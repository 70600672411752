import React, {useEffect, useRef, useState} from 'react';
import './VerifyGoogleAuthModal.scss';
import CPButton from '../button/CPButton';
import {useTranslation} from 'react-i18next';
import PinTextField from '../TextField/PinTextField';
import {GetCommon} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';
import {useDispatch} from 'react-redux';
import {setEnableGoogleAuth, token} from '../../redux/userSlice';
import api from '../../api';
import {MsgModalObject, useMsgModal} from '../../context/MsgModalContext';
import ErrMsgModal from './ErrMsgModal';

function VerifyGoogleAuthModal({
  successCB,
  cancelCB,
  googleAuthStatus = null, //null will just verify auth, true/false will enable/disable googleAuth after verified
}) {
  const {popModal} = useModal();
  const {pushMsgModal} = useMsgModal();
  const {t: trans} = useTranslation();
  const [pin, setPin] = useState('');
  const Common = GetCommon();
  const inputRef = useRef(null);

  useEffect(() => {
    if (pin.length == 6) {
      inputRef.current.unfocus();
      verifyAction({
        token: pin,
        googleAuthStatus: googleAuthStatus,
        success: (res) => {
          //updateGoogleAuthStatus will always success, googleAuthStatus != null to indicate it is calling this api.
          if (res?.data?.verified || googleAuthStatus != null) {
            if (successCB) {
              successCB();
            }
            popModal();
          } else {
            setPin('');
            pushMsgModal(
              new MsgModalObject({
                content: (
                  <ErrMsgModal title={trans('dialog.err.invalidGoogleAuth')} />
                ),
              }),
            );
          }
        },
        error: () => {
          setPin('');
        },
      });
    }
  }, [pin]);

  const verifyAction = ({token, googleAuthStatus, success, error}) => {
    if (googleAuthStatus != null) {
      api.UpdateGoogleAuthStatus({
        token: token,
        googleAuthStatus: googleAuthStatus,
        success: success,
        error: error,
      });
    } else {
      api.VerifyGoogleAuth({token: token, success: success, error: error});
    }
  };

  return (
    <div className="verifyGoogleAuthModal-main-container">
      <div className="verifyGoogleAuthModal-container">
        <img
          src={Common.modalClose}
          alt="close"
          className="verifyGoogleAuthModal-close-icon"
          onClick={() => {
            popModal();
            cancelCB?.();
          }}></img>
        <div className="verifyGoogleAuthModal-title">
          {trans('dialog.msg.googleAuthTitle')}
        </div>
        <div className="verifyGoogleAuthModal-content-container">
          <div className="verifyGoogleAuthModal-sub-title">
            {trans('dialog.msg.googleAuthDesc')}
          </div>
          <PinTextField
            ref={inputRef}
            value={pin}
            length={6}
            onChange={(e) => {
              setPin(e.value);
            }}
            containerClassName={'pinItem-googleAuth-container'}
            focusClassName={'pinItem-focus-googleAuth-container'}
            inputClassName={'pinItem-googleAuth-input'}
          />
        </div>
      </div>
    </div>
  );
}

export default VerifyGoogleAuthModal;
