import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {IsSpecialUser, SetCssVariable, useNav} from '../../common/utils/helper';
import {GetHome, GetCommon} from '../../resources/image/Image';
import {clientInfo} from '../../resources';
import MarqueeComponent from './Marquee';
import WalletDetail from '../wallet/WalletDetail';
import Footer from './Footer';
import {useDispatch, useSelector} from 'react-redux';
import {setDummy} from '../../common/redux/optionSlice';
import Dialog from '../../common/components/root/dialog/Dialog';
import './HeaderFooterLayout.scss';
import SideMenu from '../sideMenu/SideMenu';
import {openSideMenu} from '../../common/redux/sideMenuSlice';
import NavBrandLogo from '../../common/components/NavBrandLogo/NavBrandLogo';
import {useModal} from '../../common/context/ModalContext';
import {LANGUAGE_MODAL_KEY} from '../../common/utils/modalHelper';
import LanguageModal from '../../common/components/modal/LanguageModal';
import LanguageComponent from '../../common/components/language/LanguageComponent';
import DimmedComponent from '../../common/components/FocusComponent/DimmedComponent';
import {Outlet} from 'react-router-dom';

function HeaderFooterLayout({children}) {
  const {modalStack, pushModal, popModal} = useModal();
  const HomeIcon = GetHome();
  const Common = GetCommon();
  const dispatch = useDispatch();
  const topSectionRef = useRef(null);
  const topNavRef = useRef(null);
  const [selectingLang, setSelectingLang] = useState(false);
  const showHeaderFooter = useSelector(({styling}) => styling.showHeaderFooter);
  const iframeContent = useSelector(({styling}) => styling.iframeContent);
  const {sideMenuIsOpen} = useSelector((state) => state.sideMenu);

  useEffect(() => {
    if (modalStack[modalStack.length - 1]?.key === LANGUAGE_MODAL_KEY) {
      setSelectingLang(true);
    } else {
      setSelectingLang(false);
    }
  }, [modalStack]);

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (topSectionRef.current) {
        const newHeight = topSectionRef.current.getBoundingClientRect().height;
        const topNavHeight =
          (topNavRef?.current?.getBoundingClientRect()?.height ?? 0) +
          (parseFloat(
            getComputedStyle(topSectionRef?.current)?.paddingBottom,
          ) ?? 0);
        console.log(
          `haloooo ${topNavHeight} and ${
            parseFloat(
              getComputedStyle(topSectionRef?.current)?.paddingBottom,
            ) ?? 0
          } and`,
        );
        SetCssVariable('--top-header-height', `${newHeight}px`);
        SetCssVariable(
          '--top-header-height-no-nav',
          `${newHeight - topNavHeight}px`,
        );
      }
    });
    if (topSectionRef?.current) {
      observer.observe(topSectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [topSectionRef]);

  return (
    <div id="container" className="home-container">
      <SideMenu></SideMenu>
      <DimmedComponent />
      <div
        className="layout-main-container"
        style={sideMenuIsOpen ? {} : {left: '0px'}}>
        {showHeaderFooter ? (
          <div
            className="game-top"
            ref={topSectionRef}
            style={sideMenuIsOpen ? {} : {left: '0px'}}>
            <div className="game-top-1">
              <div className="game-top-container">
                <div className="game-top-left-section-container">
                  <img
                    src={HomeIcon.menu}
                    className="game-top-menu-img"
                    alt="menu-icon"
                    onClick={() => {
                      dispatch(openSideMenu(true));
                    }}></img>
                  <NavBrandLogo />
                </div>

                <div className="game-top-right-section-container">
                  {IsSpecialUser() ? null : <WalletDetail />}
                  {selectingLang ? (
                    <img
                      src={Common.modalClose}
                      className="game-top-close-icon"
                      alt="modal-close-icon"
                      onClick={() => {
                        popModal();
                      }}
                    />
                  ) : (
                    <LanguageComponent
                    // onClick={() => {
                    //   document.getElementById('more_slider').style.transform =
                    //     'translateX(0%)';
                    //   document.getElementById('more_slider').style.display =
                    //     'block';
                    //   document.body.style.overflow = 'hidden';
                    //   document.body.style.touchAction = 'none';
                    // }}
                    />
                  )}
                </div>
              </div>
              <div className="game-marquee-container">
                <MarqueeComponent />
              </div>
              {/*hide top nav for desktopview */}
              {/* <div className="headerFooterLayout-topNav" ref={topNavRef}>
                <Footer></Footer>
              </div> */}
            </div>
            {/* <div className="dummy-sideMenu-space" /> */}
          </div>
        ) : null}
        <div
          className={`${showHeaderFooter ? 'layout-page-content' : ''} ${
            iframeContent ? 'iframe-layout-page-content' : ''
          }`}>
          <div className="layout-page-content-1">
            {/* {children} */}
            <Outlet />
            {/* <div className="dummy-sideMenu-space" /> */}
          </div>
        </div>
        <div className="headerFooterLayout-footer">
          {showHeaderFooter ? <Footer></Footer> : null}
        </div>
      </div>
    </div>
  );
}

export default HeaderFooterLayout;
