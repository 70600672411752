import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './BaseTextField.scss';
import {useTranslation} from 'react-i18next';
import axios from 'axios';

const BaseTextField = forwardRef((allProps, ref) => {
  const {
    name, //throw this for convenient to trace the input in form
    isFormChild, //for formComponent to check formChild
    inputClassName,
    placeholderClassName,
    title,
    placeholder,
    value = '',
    onChange,
    inputMode,
    allowFirstZero = false, //when inputMode numeric, whether to allow first character to be 0
    type, //type number is not working correctly, please use inputMode numeric
    validation,
    maxDecimalPlaces,
    maxDigits,
    formRef, //indicate formComponent
    groupRef, //indicate groupTextFieldComponent
    isRequired,
    errMsgCallBack,
    containerRef, //scroll to this containerRef position if got value
    scrollRef, //will get it from formComponent, for those div scroll instead of body scroll
    frameRef, //the visible div ref, when scrollToTextfield will make sure inside this frame
    disabled,
    isValidatingCallBack,
    isFocusedCallBack,
    isRealTimeValidation: extIsRealTime = false, //to manually enable realtime validation
    disabledFromFormValidation = false, //if have two tf with same validation, can set either one to false, so it wont trigger twice when submit button.
    probagateOnClick = false, //control whether onClick in input should trigger parent onclick
    ...props
  } = allProps;
  const renderCount = useRef(0);
  const [errMsg, setErrMsg] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const baseContainerRef = useRef(null);
  const inputRef = useRef(null);
  const isRealTimeValidation = useRef(false);
  //used to indicate setErrMsg by ref.
  const isManualSetErr = useRef(false);
  const [isValidating, setIsValidating] = useState(false);
  const [tfCancelToken, setTfCancelToken] = useState(null);

  // const textFieldPosition = useRef(0);
  // const frameTopPosition = useRef(0);

  React.useImperativeHandle(ref, () => ({
    props: allProps,
    inputRef: inputRef.current,
    ValidateValue,
    isManualSetErr: isManualSetErr.current,
    errMsg: errMsg,
    setErrMsg: (errMsg, enableScrollTo = false) => {
      isManualSetErr.current = true;
      setErrMsg(errMsg);
      if (enableScrollTo) {
        scrollToTextField();
      }
    },
    isRealTimeValidation: isRealTimeValidation.current,
    ScrollToTextField: scrollToTextField,
  }));

  useEffect(() => {
    if (errMsgCallBack) {
      errMsgCallBack(errMsg);
    }
  }, [errMsg]);

  useEffect(() => {
    if (isValidatingCallBack) {
      isValidatingCallBack(isValidating);
    }
  }, [isValidating]);

  useEffect(() => {
    if (isFocusedCallBack) {
      isFocusedCallBack(isFocused);
    }
  }, [isFocused]);

  useEffect(() => {
    if (groupRef?.current) {
      //groupTextField component
      if (isRealTimeValidation.current) {
        groupRef.current.ValidateValue({scrollTo: false});
      }
    } else {
      if (renderCount.current > 0) {
        if (isRealTimeValidation.current || extIsRealTime) {
          ValidateValue();
        }
      }
    }
    renderCount.current += 1;
  }, [value, groupRef]);

  function scrollToTextField() {
    if (formRef) {
      if (scrollRef) {
        //if not body scroll, then can direct use scrollIntoView
        GetTextfieldContainer().current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        //default app all is using body as scroll
        const scrollContainerRect = document.body.getBoundingClientRect();
        const elementRect =
          GetTextfieldContainer().current.getBoundingClientRect();

        // Calculate the position relative to the scroll container
        const tfTop = elementRect.top - scrollContainerRect.top;
        let frameRect;
        if (frameRef) {
          frameRect = frameRef.current.getBoundingClientRect();
        } else {
          //if no frameRef default will use formRef as the frame
          frameRect = formRef?.current?.getBoundingClientRect();
        }
        const frameTop = frameRect.top - scrollContainerRect?.top;

        window.scrollTo({
          top: tfTop - frameTop,
          behavior: 'smooth',
        });
      }
    }
  }

  function handleInputChange(e) {
    const inputValue = e.target.value;
    const validatedValue = inputValidation(inputValue);
    // Call onChange with the validated value
    if (onChange && typeof onChange == 'function' && validatedValue != null) {
      // const newEventObject = {...e};
      // newEventObject.target.value = validatedValue; //cannot use spread operator on e.target will missing something
      onChange({name: name, value: validatedValue});
    }
  }

  function inputValidation(inputValue) {
    if (inputMode == 'numeric' || inputMode == 'decimal') {
      let decimalRegex =
        maxDecimalPlaces && maxDecimalPlaces > 0
          ? `(\\.\\d{0,${maxDecimalPlaces}})?`
          : '';

      let maxDigitsRegex = maxDigits ? `\\d{0,${maxDigits - 1}}` : '\\d*';
      let firstCharRegex = `1-9`;
      if (allowFirstZero) {
        firstCharRegex = `0-9`;
      }

      const pattern = new RegExp(
        `^$|^[${firstCharRegex}]${maxDigitsRegex}${decimalRegex}$`,
      );
      if (pattern.test(inputValue)) {
        return inputValue;
      } else {
        return null;
      }
    } else {
      if (maxDigits) {
        if (inputValue.length > maxDigits) {
          return null;
        }
      }
    }
    return inputValue;
  }

  async function ValidateValue() {
    //if execute this method mean not manual err anymore
    isManualSetErr.current = false;

    setIsValidating(true);
    isRealTimeValidation.current = true;
    if (isRequired && value.trim().length == 0) {
      setErrMsg('general.errMsg.fieldRequired');
      setIsValidating(false);
      return false;
    }
    //for faster to remove the require err msg, if the validation is async
    setErrMsg('');
    if (validation) {
      // Cancel the previous API request if there is one
      if (tfCancelToken) {
        tfCancelToken.cancel();
      }

      // Create a new cancel token
      const source = axios.CancelToken.source();
      setTfCancelToken(source);

      const newErrMsg = await validation(value, source);

      //return value if contain isRemain, will remain the previous errMsg
      //use case for cancelled api.
      console.log(`checking textfield ${isValidating} `, newErrMsg);
      if (newErrMsg?.isRemain) {
        return errMsg == '' ? true : false;
      }
      if (newErrMsg) {
        //if async validation will need this checking
        //cannot use value, because it is not the latest state
        // const currentValue = inputRef?.current?.value || '';
        // console.log(`checking textfield value ${currentValue} and ${value}`);
        // if (!(isRequired && currentValue.trim().length == 0)) {
        setErrMsg(newErrMsg);
        // }
        setIsValidating(false);
        return false;
      }
      setErrMsg('');
      setIsValidating(false);
      return true;
    }
    setErrMsg('');
    setIsValidating(false);
    return true;
  }

  function GetTextfieldContainer() {
    return containerRef ? containerRef : baseContainerRef;
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const refValue = formRef?.current;
      if (refValue && refValue.onSubmit) {
        inputRef?.current?.blur();
        refValue.onSubmit();
      }
    }
  }

  return (
    <div className={`baseTextField-container`} ref={baseContainerRef}>
      {!disabled && value.length > 0 ? null : (
        <div
          className={`${
            disabled ? `` : `baseTextField-placeholder-container`
          } baseTextField-input ${
            value.toString().length > 0
              ? inputClassName
              : `baseTextField-placeholder ${placeholderClassName}`
          }`}>
          {value.length > 0 ? value : placeholder}
        </div>
      )}
      {disabled ? null : (
        <input
          inputMode={inputMode}
          className={`baseTextField-input ${inputClassName}`}
          value={value}
          onChange={handleInputChange}
          type={type}
          ref={inputRef}
          disabled={disabled}
          autoComplete="off"
          {...props}
          onClick={(e) => {
            if (!probagateOnClick) {
              e.stopPropagation();
            }
          }}
          onFocus={(e) => {
            setIsFocused(true);
            if (props.onFocus) props.onFocus(e);
          }}
          onBlur={(e) => {
            setIsFocused(false);
            if (props.onBlur) props.onBlur(e);
          }}
          onKeyDown={(e) => {
            handleKeyDown(e);
            if (props.onKeyDown) props.onKeyDown(e);
          }}
        />
      )}
    </div>
  );
});

export default BaseTextField;
