import React, {useEffect, useRef, useState} from 'react';
import './Login.scss';
import LoginCommonTf from '../../common/components/TextField/LoginCommonTf';
import FormComponent from '../../common/components/Form/FormComponent';
import {GetLogin, GetTextField} from '../../resources/image/Image';
import PasswordTextField from '../../common/components/TextField/PasswordTextField';
import RememberComponent from '../../common/components/LoginComponent/RememberComponent';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import {useTranslation} from 'react-i18next';
import ThirdPartyLoginComponent from '../../common/components/LoginComponent/ThirdPartyLoginComponent';
import LoginOrSeparator from '../../common/components/LoginComponent/LoginOrSeparator';
import LoginMobileTf from '../../common/components/TextField/LoginMobileTf';
import api from '../../common/api';
import {
  IsOtpRequired,
  decryptAndRetrieveObj,
  encryptAndStoreObj,
  useNav,
  useVerifyPasswordAndAuth,
} from '../../common/utils/helper';
import {clientInfo} from '../../resources';
import {
  LoginAccount,
  ContinueJourneyNavigate,
  LOGIN_RMB_KEY,
  LoginWithLine,
  VERIFYMOBILE_PREV_PAGE_KEY,
} from '../../common/utils/loginHelper';
import {useSelector} from 'react-redux';

export default function Login() {
  const {t: trans} = useTranslation();
  const {lineClientId, skipOtp} = useSelector(({company}) => company);
  const navigate = useNav();
  const mobileTfName = 'mobile';
  const mobileCountryCode = 'mCountryCode';
  const pwTfName = 'password';
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const [formData, setFormData] = useState({
    [mobileCountryCode]: clientInfo.defaultMobileCode,
  });
  const [isRemember, setIsRemember] = useState(false);
  const tfImg = GetTextField();
  const verifyPasswordAndAuth = useVerifyPasswordAndAuth();

  useEffect(async () => {
    const savedLoginInfo = await decryptAndRetrieveObj(LOGIN_RMB_KEY);
    if (savedLoginInfo) {
      setIsRemember(true);
      setFormData((exisValue) => {
        return {...exisValue, ...savedLoginInfo};
      });
    }
  }, []);

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue();
    console.log(formData, isRemember);
    if (isValid) {
      const mobileCode = formData[mobileCountryCode];
      const mobile = formData[mobileTfName];
      const password = formData[pwTfName];
      console.log(`is otp required ? ${skipOtp}`);
      // const otpRequired = skipOtp ? false : IsOtpRequired(mobile);
      verifyPasswordAndAuth({
        userId: mobile,
        password: password,
        autoLogin: false,
        success: (res) => {
          if (isRemember) {
            const loginInfo = {
              [mobileCountryCode]: mobileCode,
              [mobileTfName]: mobile,
              [pwTfName]: password,
            };
            encryptAndStoreObj(LOGIN_RMB_KEY, loginInfo);
          } else {
            localStorage.removeItem(LOGIN_RMB_KEY);
          }
          const otpRequired = res.data.skipOtp ? false : IsOtpRequired(mobile);
          if (otpRequired) {
            navigate(`/${VERIFYMOBILE_PREV_PAGE_KEY.login}/verifyMobile`, {
              state: {
                mobile: mobile,
                fromForgotPin: true,
                userData: res.data,
              },
            });
            return;
          } else {
            LoginAccount(res.data);
            ContinueJourneyNavigate(navigate);
          }
        },
      });
    }
  };

  return (
    <div className="login-container">
      <FormComponent
        ref={formRef}
        submitBtnRef={submitBtnRef}
        className="login-form-container">
        {clientInfo.isUsernameIdentify ? (
          <LoginCommonTf
            isFormChild={true}
            icon={tfImg.user}
            name={mobileTfName}
            placeholder={trans('general.placeholder.username')}
            value={formData[mobileTfName] || ''}
            isRequired={true}
            onChange={handleFieldChange}
          />
        ) : (
          <LoginMobileTf
            isFormChild={true}
            name={mobileTfName}
            placeholder={trans('general.label.mobileNo')}
            countryCode={formData[mobileCountryCode] || ''}
            value={formData[mobileTfName] || ''}
            isRequired={true}
            onChange={handleFieldChange}
            countryCodeOnChange={(value) => {
              updateFormData(mobileCountryCode, value);
            }}
          />
        )}
        <PasswordTextField
          isFormChild={true}
          name={pwTfName}
          placeholder={trans('general.placeholder.mobileOrPin')}
          value={formData[pwTfName] || ''}
          onChange={handleFieldChange}
          formatValidation={false}
          isRequired={true}></PasswordTextField>
      </FormComponent>
      <div className="login-forgot-section-container">
        <RememberComponent isTick={isRemember} onChange={setIsRemember} />
        {/* <div
          className="login-forgot-txt"
          onClick={() => {
            navigate(
              `/${VERIFYMOBILE_PREV_PAGE_KEY.forgotPassword}/verifyMobile`,
              {
                state: {
                  fromForgotPin: true,
                },
              },
            );
          }}>
          {trans('login.forgotPw')}
        </div> */}
      </div>
      <div className="login-btn-section-container">
        <ButtonComponent_v2
          ref={submitBtnRef}
          text={trans('signIn.login')}
          btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
          btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
          onClick={() => {
            handleButtonClick();
          }}
        />
        <LoginOrSeparator />
        <ThirdPartyLoginComponent
          onClick={(value) => {
            switch (value) {
              case 'line':
                LoginWithLine(lineClientId);
                break;

              default:
                break;
            }
          }}
        />
      </div>
    </div>
  );
}
