import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './DatePickerTextField.scss';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.min.css';
import {GetTextField} from '../../../resources/image/Image';

const DatePickerInputComponent = forwardRef(
  ({value, onClick, placeholder, className}, ref) => {
    const showPlaceholder = value ? false : true;
    if (value) {
      console.log(`got value ${value} `, value.length);
    } else {
      console.log(`no value `, value);
    }

    console.log(`halo heer ref `, ref);
    console.log(
      `halo here value ${showPlaceholder} ${value} and ${placeholder}`,
    );
    return (
      // <input value={value} {...props} disabled={true} ref={ref} />
      <div
        onClick={onClick}
        style={{cursor: 'pointer'}}
        className={
          className + ` ${showPlaceholder ? 'datePickerInput-placeholder' : ''}`
        }>
        {showPlaceholder ? placeholder : value}
      </div>
    );
  },
);

const DatePickerTextField = forwardRef((allProps, ref) => {
  const {
    value = '',
    placeholder,
    onChange,
    minDate = null, //Date object
    maxDate = null, //Date object
    name, //throw this for convenient to trace the input in form
    isFormChild, //for formComponent to check formChild
    inputClassName,
    formRef, //indicate formComponent
    groupRef, //indicate groupTextFieldComponent
    isRequired,
    errMsgCallBack,
    containerRef, //scroll to this containerRef position if got value
    scrollRef, //will get it from formComponent, for those div scroll instead of body scroll
    frameRef, //the visible div ref, when scrollToTextfield will make sure inside this frame
    isFocusedCallBack,
    isRealTimeValidation: extIsRealTime = false, //to manually enable realtime validation
    ...props
  } = allProps;
  const renderCount = useRef(0);
  const [inputValue, setInputValue] = useState(value);
  const [errMsg, setErrMsg] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const baseContainerRef = useRef(null);
  const inputRef = useRef(null);
  const customInputRef = useRef(null);
  const dateFormat = 'dd/MM/yyyy'; // Define your desired date format
  const isRealTimeValidation = useRef(false);
  //used to indicate setErrMsg by ref.
  const isManualSetErr = useRef(false);
  const textfieldImg = GetTextField();

  React.useImperativeHandle(ref, () => ({
    props: allProps,
    inputRef: inputRef.current,
    ValidateValue,
    isManualSetErr: isManualSetErr.current,
    errMsg: errMsg,
    setErrMsg: (errMsg, enableScrollTo = false) => {
      isManualSetErr.current = true;
      setErrMsg(errMsg);
      if (enableScrollTo) {
        scrollToTextField();
      }
    },
    isRealTimeValidation: isRealTimeValidation.current,
    ScrollToTextField: scrollToTextField,
  }));
  useEffect(() => {
    if (errMsgCallBack) {
      errMsgCallBack(errMsg);
    }
  }, [errMsg]);

  useEffect(() => {
    console.log(`is focused ${isFocused}`);
    if (isFocusedCallBack) {
      isFocusedCallBack(isFocused);
    }
  }, [isFocused]);

  useEffect(() => {
    if (groupRef?.current) {
      //groupTextField component
      if (isRealTimeValidation.current) {
        groupRef.current.ValidateValue({scrollTo: false});
      }
    } else {
      if (renderCount.current > 0) {
        if (isRealTimeValidation.current || extIsRealTime) {
          ValidateValue();
        }
      }
    }
    renderCount.current += 1;
  }, [value, groupRef]);

  useEffect(() => {
    if (onChange && typeof onChange == 'function') {
      onChange({name: name, value: inputValue});
    }
  }, [inputValue]);

  function scrollToTextField() {
    if (formRef) {
      if (scrollRef) {
        //if not body scroll, then can direct use scrollIntoView
        GetTextfieldContainer().current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        //default app all is using body as scroll
        const scrollContainerRect = document.body.getBoundingClientRect();
        const elementRect =
          GetTextfieldContainer().current.getBoundingClientRect();

        // Calculate the position relative to the scroll container
        const tfTop = elementRect.top - scrollContainerRect.top;
        let frameRect;
        if (frameRef) {
          frameRect = frameRef.current.getBoundingClientRect();
        } else {
          //if no frameRef default will use formRef as the frame
          frameRect = formRef?.current?.getBoundingClientRect();
        }
        const frameTop = frameRect.top - scrollContainerRect?.top;

        window.scrollTo({
          top: tfTop - frameTop,
          behavior: 'smooth',
        });
      }
    }
  }

  function GetTextfieldContainer() {
    return containerRef ? containerRef : baseContainerRef;
  }

  async function ValidateValue() {
    isRealTimeValidation.current = true;
    if (isRequired && value == '') {
      setErrMsg('general.errMsg.fieldRequired');
      return false;
    }

    setErrMsg('');
    return true;
  }

  // Function to format the input value as the user types
  const formatInput = (input) => {
    if (input.length < inputValue.length) {
      if (inputValue.endsWith('/')) {
        return input.slice(0, input.length - 1);
      }
    }
    const formattedDate = input.replace(/\D/g, '');
    if (input.length >= 2) {
      return (
        formattedDate.slice(0, 2) +
        '/' +
        formattedDate.slice(2, 4) +
        (formattedDate.length > 3 ? '/' : '') +
        (formattedDate.length > 4 ? formattedDate.slice(4) : '')
      );
    } else {
      return formattedDate;
    }
  };

  // Event handler for input changes
  const handleInputChange = (e) => {
    if (e?.target?.value != null) {
      const date = e.target.value;
      const formattedValue = formatInput(date);

      setInputValue(formattedValue);
    }
  };

  function parseDateByFormat(dateString) {
    // Split the date string into day, month, and year components
    const [day, month, year] = dateString.split('/').map(Number);

    return new Date(`${year}/${month}/${day}`);
  }

  //this parsing will auto increase the year or month if date element is exceeded the range
  //so it will get the correct value
  function parseToCorrectDateString(dateString) {
    // Split the date string into day, month, and year components
    const [day, month, year] = dateString.split('/').map(Number);

    // Create a new Date object (months are 0-based, so we subtract 1 from the month)
    const newDate = new Date(year, month - 1, day);

    const newDay = newDate.getDate().toString().padStart(2, '0');
    const newMonth = (newDate.getMonth() + 1).toString().padStart(2, '0');
    const newYear = newDate.getFullYear();

    return `${newDay}/${newMonth}/${newYear}`;
  }

  function isValidDate(date) {
    return date.getTime() === date.getTime();
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const refValue = formRef?.current;
      if (refValue && refValue.onSubmit) {
        inputRef?.current?.cancelFocusInput();
        refValue.onSubmit();
      }
    }
  }

  return (
    <div className="datePickerTextField-main-container" ref={baseContainerRef}>
      <ReactDatePicker
        wrapperClassName="datePickerTextField-container"
        className={`datePickerTextField-container ${inputClassName}`}
        dateFormat={dateFormat}
        dateFormatCalendar={dateFormat}
        strictParsing={true}
        onChangeRaw={handleInputChange}
        onChange={(date) => {
          //handle for clear btn only
          if (date == null) {
            setInputValue(null);
          }
        }}
        onSelect={(date) => {
          const options = {day: '2-digit', month: '2-digit', year: 'numeric'};
          const formattedDate = date.toLocaleDateString('en-GB', options);
          setInputValue(formattedDate);
        }}
        minDate={minDate}
        maxDate={maxDate}
        onBlur={(e) => {
          setIsFocused(false);
          if (props.onBlur) props.onBlur(e);

          const [day, month, year] = inputValue.split('/');

          if (year == null || year.length < 4) {
            setInputValue('');
            return;
          }
          const currentDate = parseDateByFormat(inputValue);
          if (isValidDate(currentDate)) {
            let isValid = true;
            if (minDate != null && currentDate < minDate) {
              isValid = false;
            }

            if (maxDate != null && currentDate > maxDate) {
              isValid = false;
            }

            if (!isValid) {
              setInputValue('');
            } else {
              setInputValue(parseToCorrectDateString(inputValue));
            }
          } else {
            setInputValue('');
          }
        }}
        showYearDropdown={true}
        showMonthDropdown={true}
        dropdownMode="select"
        selected={
          inputValue && isValidDate(parseDateByFormat(inputValue))
            ? parseDateByFormat(inputValue)
            : null
        }
        value={inputValue}
        placeholderText={placeholder ? placeholder : dateFormat.toUpperCase()}
        popperModifiers={[
          {
            name: 'arrow',
            options: {
              padding: ({popper, reference, placement}) => {
                return {
                  right: popper.width - 24,
                };
              },
            },
          },
        ]}
        onFocus={(e) => {
          setIsFocused(true);
          if (props.onFocus) props.onFocus(e);
        }}
        onKeyDown={(e) => {
          //currently no way to do enter submit in picker, since it wont cancel focus in input.
          // handleKeyDown(e);
          if (props.onKeyDown) props.onKeyDown(e);
        }}
        ref={inputRef}
        customInputRef={customInputRef}
        customInput={
          <DatePickerInputComponent ref={customInputRef} />
          // <input inputMode="numeric" disabled={true} />
        }></ReactDatePicker>
      {value ? (
        <img
          className="datePickerTextField-clear-img"
          src={textfieldImg.clear}
          onClick={() => {
            inputRef?.current?.clear?.();
          }}
        />
      ) : null}
    </div>
  );
});

export default DatePickerTextField;
