import {configureStore} from '@reduxjs/toolkit';
import stylingSlice from './stylingSlice';
import pagesSlice from './pagesSlice';
import userSlice from './userSlice';
import othersSlice from './othersSlice';
import optionSlice from './optionSlice';
import companySlice from './companySlice';
import logger from 'redux-logger';
import providerSlice from './game/providerSlice';
import categorySlice from './game/categorySlice';
import sideMenuSlice from './sideMenuSlice';
import appGameSlice from './game/appGameSlice';
import journeySlice from './journeySlice';
import gameSlice from './game/gameSlice';
import securitySlice from './securitySlice';

const isPrd = process.env.NODE_ENV === 'production';

export const store = configureStore({
  reducer: {
    option: optionSlice,
    user: userSlice,
    pages: pagesSlice,
    styling: stylingSlice,
    others: othersSlice,
    company: companySlice,
    provider: providerSlice,
    category: categorySlice,
    sideMenu: sideMenuSlice,
    appGame: appGameSlice,
    journey: journeySlice,
    game: gameSlice,
    security: securitySlice,
  },
  middleware: (getDefaultMiddleware) => {
    const middlewares = [...(isPrd ? [] : [logger])];

    return getDefaultMiddleware().concat(middlewares);
  },
});
