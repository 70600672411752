import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './BankDropDownBorderTextField.scss';
import PopUpComponent from '../PopUpComponent/PopUpComponent';
import {useTranslation} from 'react-i18next';
import {GetTextField, GetWallet} from '../../../resources/image/Image';
import {useSelector} from 'react-redux';

const BankDropDownBorderTextField = forwardRef((allProps, ref) => {
  const {
    name,
    value,
    isRequired,
    validation,
    formRef,
    scrollRef,
    frameRef,
    onChange,
    placeholder,
    disabled,
    isRealTimeValidation: extIsRealTime = false, //to manually enable realtime validation
    disabledFromFormValidation = false, //if have two tf with same validation, can set either one to false, so it wont trigger twice when submit button.
    ...props
  } = allProps;
  const renderCount = useRef(0);
  const {t: trans} = useTranslation();
  const tfImg = GetTextField();
  const walletImg = GetWallet();
  const baseContainerRef = useRef(null);
  const isRealTimeValidation = useRef(false);
  const [errMsg, setErrMsg] = useState('');
  const availBankList = useSelector((state) => state.company.availBankList);

  const [isOpen, setIsOpen] = useState(false);

  React.useImperativeHandle(ref, () => ({
    props: allProps,
    ValidateValue,
    setErrMsg: (errMsg) => {
      isRealTimeValidation.current = true;
      setErrMsg(errMsg);
    },
    ScrollToTextField: () => {
      if (formRef) {
        if (scrollRef) {
          //if not body scroll, then can direct use scrollIntoView
          baseContainerRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        } else {
          //default app all is using body as scroll
          const scrollContainerRect = document.body.getBoundingClientRect();
          const elementRect = baseContainerRef.current.getBoundingClientRect();

          // Calculate the position relative to the scroll container
          const tfTop = elementRect.top - scrollContainerRect.top;
          let frameRect;
          if (frameRef) {
            frameRect = frameRef.current.getBoundingClientRect();
          } else {
            //if no frameRef default will use formRef as the frame
            frameRect = formRef?.current?.getBoundingClientRect();
          }
          const frameTop = frameRect.top - scrollContainerRect?.top;

          window.scrollTo({
            top: tfTop - frameTop,
            behavior: 'smooth',
          });
        }
      }
    },
  }));

  useEffect(() => {
    if (renderCount.current > 0) {
      if (isRealTimeValidation.current || extIsRealTime) {
        ValidateValue();
      }
    }
    renderCount.current += 1;
  }, [value]);

  async function ValidateValue() {
    isRealTimeValidation.current = true;
    if (isRequired && !value) {
      setErrMsg('general.errMsg.fieldRequired');
      return false;
    }
    //for faster to remove the require err msg, if the validation is async
    setErrMsg('');
    if (validation) {
      const newErrMsg = await validation(value);
      //return value if contain isRemain, will remain the previous errMsg
      //use case for cancelled api.
      if (newErrMsg?.isRemain) {
        return errMsg == '' ? true : false;
      }

      if (newErrMsg) {
        setErrMsg(newErrMsg);
        return false;
      }
      setErrMsg('');
      return true;
    }
    setErrMsg('');
    return true;
  }

  const OptionItem = ({bankObject, onClick}) => {
    return (
      <div
        className="bankDropDownBorderTextField-item-container"
        onClick={() => {
          if (onClick) onClick(bankObject);
        }}>
        <div className="bankDropDownBorderTextField-item-content-container">
          <img
            className="bankDropDownBorderTextField-item-bank-img"
            src={walletImg.bank[bankObject.bankCode]}
            alt={bankObject.bankCode}
          />

          <div className="bankDropDownBorderTextField-item-bank-name">
            {bankObject.bankName}
          </div>
        </div>
        <div className="bankDropDownBorderTextField-item-separator" />
      </div>
    );
  };

  function GetBankOptions() {
    return availBankList.map((item, index) => {
      return (
        <OptionItem
          key={index}
          bankObject={item}
          onClick={(selectedItem) => {
            if (onChange) onChange({name: name, value: selectedItem});
            setIsOpen(false);
          }}
        />
      );
    });
  }
  return (
    <PopUpComponent
      onClick={() => {
        if (!disabled) setIsOpen(true);
      }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popUpElement={
        <div className="bankDropDownBorderTextField-popup-bank-container">
          <div
            className="bankDropDownBorderTextField-popup-title-container"
            onClick={() => {
              setIsOpen(false);
            }}>
            <div style={{width: '18px'}} />
            <div className="bankDropDownBorderTextField-popup-title">
              {trans('general.placeholder.selectBank')}
            </div>
            <img
              alt="arrow"
              className="bankDropDownBorderTextField-popup-title-img"
              src={tfImg.up}
            />
          </div>
          <div className="bankDropDownBorderTextField-item-separator" />
          <div className="bankDropDownBorderTextField-popup-bank-options-container">
            {GetBankOptions()}
          </div>
        </div>
      }>
      <div
        ref={baseContainerRef}
        className="bankDropDownBorderTextField-container"
        style={disabled ? {opacity: '0.7'} : {}}>
        <div
          className={`bankDropDownBorderTextField-input-container ${
            errMsg ? 'bankDropDownBorderTextField-error-input-container' : ''
          }`}>
          <div style={{width: '18px'}} />
          <div style={{flex: '1'}}>
            {value ? (
              <div className="bankDropDownBorderTextField-value-section">
                <img
                  className="bankDropDownBorderTextField-value-img"
                  src={walletImg.bank[value.bankCode]}
                />
                <div className="bankDropDownBorderTextField-value-txt">
                  {value.bankName}
                </div>
              </div>
            ) : (
              <div className="bankDropDownBorderTextField-placeholder-txt">
                {placeholder ? placeholder : ' '}
              </div>
            )}
          </div>

          <img
            className="bankDropDownBorderTextField-dropdown-img"
            src={tfImg.dropdownLight}
            alt="dropdown"
          />
        </div>

        {errMsg ? (
          <div className="bankDropDownBorderTextField-error-msg">
            {trans(errMsg)}
          </div>
        ) : null}
      </div>
    </PopUpComponent>
  );
});

export default BankDropDownBorderTextField;
