import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './ProhibitionDropDown.scss';
import './TextFieldComponent';
import BaseTextField from './BaseTextField';
import {GetTextField} from '../../../resources/image/Image';
import {useTranslation} from 'react-i18next';
import PopUpComponent from '../PopUpComponent/PopUpComponent';
import {isEqual} from 'lodash';

/*
example itemList:
[
    {
        years: 1,
        months: 1,
        weeks: 1,
        days: 1
    }
]
*/

const getDisplayText = (item, trans) => {
  if (!item) {
    return '';
  }
  let resolvedText = '';
  const yearValue = item.years;
  const monthValue = item.months;
  const weekValue = item.weeks;
  const dayValue = item.days;
  if (yearValue) {
    resolvedText = combineValue(
      resolvedText,
      trans('dateUnit.years', {
        dateUnit: yearValue,
        count: Number(yearValue),
      }),
    );
  }
  if (monthValue) {
    resolvedText = combineValue(
      resolvedText,
      trans('dateUnit.months', {
        dateUnit: monthValue,
        count: Number(monthValue),
      }),
    );
  }
  if (weekValue) {
    resolvedText = combineValue(
      resolvedText,
      trans('dateUnit.weeks', {dateUnit: weekValue, count: Number(weekValue)}),
    );
  }
  if (dayValue) {
    resolvedText = combineValue(
      resolvedText,
      trans('dateUnit.days', {dateUnit: dayValue, count: Number(dayValue)}),
    );
  }

  return resolvedText;
};

const combineValue = (existingStr, targetStr) => {
  if (existingStr) {
    return existingStr + ' ' + targetStr;
  } else {
    return targetStr;
  }
};

const ProhibitionDropDown = forwardRef((allProps, ref) => {
  const {itemList, disabled, onChange, name, value, ...props} = allProps;
  const {t: trans} = useTranslation();
  const tfImg = GetTextField();
  const containerRef = useRef(null);
  const [errMsg, setErrMsg] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const handleErrMsg = (errMsg) => {
    setErrMsg(errMsg);
  };

  const GetOptionItem = () => {
    let sortedList = itemList;
    if (value) {
      sortedList = sortedList.filter((item) => {
        return !isEqual(item, value);
      });
      sortedList = [value, ...sortedList];
    }
    return (
      <div className="prohibitionDropDown-popUp-container">
        {sortedList.map((item, index) => {
          return (
            <ProhibitionDropDownItem
              key={index}
              item={item}
              isFirstItem={index == 0}
              onClick={(cbItem) => {
                if (onChange) onChange({name: name, value: cbItem});
                setIsOpen(false);
              }}
            />
          );
        })}
      </div>
    );
  };
  return (
    <PopUpComponent
      onClick={() => {
        if (!disabled) setIsOpen(true);
      }}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      popUpElement={GetOptionItem()}>
      <div ref={containerRef} className="prohibitionDropDown-container">
        <div className="prohibitionDropDown-input-container">
          <div style={{flex: '1'}}>
            <BaseTextField
              {...props}
              value={getDisplayText(value, trans)}
              containerRef={containerRef}
              ref={ref}
              errMsgCallBack={handleErrMsg}
              inputClassName={'prohibitionDropDown-input'}
              placeholderClassName={'prohibitionDropDown-placeholder'}
              disabled={true}
              probagateOnClick={true}></BaseTextField>
          </div>

          <img
            className="prohibitionDropDown-dropdown-img"
            src={tfImg.dd_down_light}
            alt="dropdown"
          />
        </div>

        <div className="prohibitionDropDown-error-container">
          {errMsg ? (
            <div className="prohibitionDropDown-error-msg">{trans(errMsg)}</div>
          ) : null}
        </div>
      </div>
    </PopUpComponent>
  );
});

const ProhibitionDropDownItem = ({item, isFirstItem, onClick}) => {
  const {t: trans} = useTranslation();
  const tfImg = GetTextField();

  const nonSelected = isFirstItem
    ? ''
    : 'prohibitionDropDownItem-popUp-item-hidden-icon';

  return (
    <div
      className="prohibitionDropDownItem-container"
      onClick={() => {
        if (onClick) onClick(item);
      }}>
      <div className="prohibitionDropDownItem-txt">
        {getDisplayText(item, trans)}
      </div>
      <img
        className={`prohibitionDropDown-dropdown-img ${nonSelected}`}
        alt={`drop down`}
        src={tfImg.dd_up_light}
      />
    </div>
  );
};

export default ProhibitionDropDown;
