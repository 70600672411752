import React from 'react';
import './ThirdPartyLoginItemComponent.scss';

export default function ThirdPartyLoginItemComponent({
  alt,
  imgSrc,
  onClick,
  className,
}) {
  return (
    <div
      className={`thirdPartyLoginItem-container ${className}`}
      onClick={onClick}>
      <img className="thirdPartyLoginItem-img" alt={alt} src={imgSrc} />
    </div>
  );
}
