import {Route} from 'react-router-dom';
import {lazy} from 'react';
const PlayGame = lazy(() => import('../home/PlayGame'));
const Play = lazy(() => import('../home/Play'));

export function GetFullScreenRoutes() {
  const routes = [
    {
      path: '/play',
      element: <Play />,
    },
    {
      path: '/playgame',
      element: <PlayGame />,
    },
  ];

  return routes.map((route, index) => (
    <Route key={index} path={route.path} element={route.element} />
  ));
}
