import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './BaseCheckBox.scss';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {GetTextField} from '../../../resources/image/Image';

const BaseCheckBox = forwardRef((allProps, ref) => {
  const {
    name, //throw this for convenient to trace the input in form
    isFormChild, //for formComponent to check formChild
    value = false,
    onChange,
    formRef, //indicate formComponent
    groupRef, //indicate groupTextFieldComponent
    isRequired,
    errMsgCallBack,
    containerRef, //scroll to this containerRef position if got value
    scrollRef, //will get it from formComponent, for those div scroll instead of body scroll
    frameRef, //the visible div ref, when scrollToTextfield will make sure inside this frame
    ...props
  } = allProps;
  const textfieldImg = GetTextField();
  const [errMsg, setErrMsg] = useState('');
  const baseContainerRef = useRef(null);
  const isRealTimeValidation = useRef(false);

  React.useImperativeHandle(ref, () => ({
    ValidateValue,
    setErrMsg: setErrMsg,
    ScrollToTextField: scrollToTextField,
  }));

  useEffect(() => {
    //trigger onchange first load, to get the default value
    handleInputChange(value == null ? false : value);
  }, []);

  useEffect(() => {
    if (errMsgCallBack) {
      errMsgCallBack(errMsg);
    }
  }, [errMsg]);

  useEffect(() => {
    if (groupRef?.current) {
      //groupTextField component
      if (isRealTimeValidation.current) {
        groupRef.current.ValidateValue({scrollTo: false});
      }
    } else {
      if (isRealTimeValidation.current) {
        ValidateValue();
      }
    }
  }, [value, groupRef]);

  function scrollToTextField() {
    if (formRef) {
      if (scrollRef) {
        //if not body scroll, then can direct use scrollIntoView
        GetInputContainer().current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      } else {
        //default app all is using body as scroll
        const scrollContainerRect = document.body.getBoundingClientRect();
        const elementRect = GetInputContainer().current.getBoundingClientRect();

        // Calculate the position relative to the scroll container
        const tfTop = elementRect.top - scrollContainerRect.top;
        let frameRect;
        if (frameRef) {
          frameRect = frameRef.current.getBoundingClientRect();
        } else {
          //if no frameRef default will use formRef as the frame
          frameRect = formRef?.current?.getBoundingClientRect();
        }
        const frameTop = frameRect.top - scrollContainerRect?.top;

        window.scrollTo({
          top: tfTop - frameTop,
          behavior: 'smooth',
        });
      }
    }
  }

  function handleInputChange(newValue) {
    if (onChange && typeof onChange == 'function') {
      onChange({name: name, value: newValue});
    }
  }

  async function ValidateValue() {
    isRealTimeValidation.current = true;
    if (isRequired && value != true) {
      setErrMsg('general.errMsg.fieldRequired');
      return false;
    }

    setErrMsg('');
    return true;
  }

  function GetInputContainer() {
    return containerRef ? containerRef : baseContainerRef;
  }

  return (
    <img
      ref={baseContainerRef}
      onClick={() => {
        handleInputChange(!value);
      }}
      className="baseCheckBox-container"
      src={value ? textfieldImg.checked : textfieldImg.uncheck}
    />
  );
});

export default BaseCheckBox;
