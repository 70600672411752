import React, {useEffect, useState, useRef} from 'react';
import './SetPinModal.scss';
import {GetCommon} from '../../../resources/image/Image';
import {useModal} from '../../context/ModalContext';

import {useTranslation} from 'react-i18next';
import {useLocation, useNavigate} from 'react-router-dom';

import CPPin from '../../../common/components/pin/CPPin';
import api from '../../../common/api';
import Dialog from '../../../common/components/root/dialog/Dialog';
import {clearReferral} from '../../../common/utils/helper';
import {ENTERPIN_MODAL_KEY, SIGNIN_MODAL_KEY} from '../../utils/loginHelper';
import EnterPinModal from './EnterPinModal';
import SignInModal from './SignInModal';

function SetPinModal({mobile, fromForgotPin, refcode, lineUuid, state}) {
  const {popLastAndPushModal, popModal} = useModal();
  const Common = GetCommon();
  const {t: trans} = useTranslation();
  let location = useLocation();
  const navigate = useNavigate();
  const pinRef = useRef(null);

  const from = state?.from;
  const extraState = state?.extraState;
  const [pin, setPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [statePin, setStatePin] = useState(1);
  const [title, setTitle] = useState('');
  const [ref, setRef] = useState(refcode);

  useEffect(() => {
    switch (statePin) {
      case 1:
        setTitle(trans('general.title.newPin'));
        break;
      case 2:
        setTitle(trans('general.title.confirmPin'));
    }
  }, [statePin]);

  useEffect(() => {
    if (pin.length == 6) {
      switch (statePin) {
        case 1:
          setStatePin(2);
          setNewPin(pin);
          pinRef.current.reset();
          setPin('');
          break;

        case 2:
          if (newPin !== pin) {
            Dialog.show({
              show: true,
              msg: trans('dialog.err.pin'),
              type: 'error',
              btnAct: () => {
                setStatePin(1);
                setPin('');
                pinRef.current.reset(true);
              },
            });
          } else {
            if (fromForgotPin) {
              api.ForgotPin({
                mobileNumber: mobile,
                password: pin,
                success: () => {
                  popLastAndPushModal({
                    key: ENTERPIN_MODAL_KEY,
                    content: (
                      <EnterPinModal
                        mobile={mobile}
                        state={state}></EnterPinModal>
                    ),
                    showAnim: false,
                  });
                },
                error: () => {
                  setStatePin(1);
                  setPin('');
                  pinRef.current.reset(true);
                },
              });
            } else {
              api.SignUp({
                mobileNumber: mobile,
                referrer: ref,
                password: pin,
                lineUuid,
                success: () => {
                  localStorage.setItem('verified', mobile);
                  clearReferral();
                  popLastAndPushModal({
                    key: ENTERPIN_MODAL_KEY,
                    content: (
                      <EnterPinModal
                        mobile={mobile}
                        state={state}></EnterPinModal>
                    ),
                    showAnim: false,
                  });
                },
                error: () => {
                  setRef('');
                  setStatePin(1);
                  setPin('');
                  pinRef.current.reset(true);
                  popLastAndPushModal({
                    key: SIGNIN_MODAL_KEY,
                    content: <SignInModal state={state}></SignInModal>,
                    showAnim: false,
                  });
                },
              });
            }
          }
          break;
      }
    }
  }, [pin]);

  return (
    <div className="setPin-modal-main-container">
      <img
        src={Common.modalClose}
        alt="close"
        className="setPin-modal-close-icon"
        onClick={() => {
          popModal();
        }}></img>
      <div className="setPin-modal-container">
        <div className="setPin-modal-title">{title}</div>
        <CPPin ref={pinRef} pin={6} onChangeValue={setPin} />
      </div>
      {fromForgotPin ? null : (
        <img
          src={Common.step3}
          alt="step-img"
          className="setPin-modal-step-icon"
        />
      )}
    </div>
  );
}

export default SetPinModal;
