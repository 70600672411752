import React from 'react';
import './ThirdPartyLoginComponent.scss';
import {GetLogin} from '../../../resources/image/Image';
import ThirdPartyLoginItemComponent from './ThirdPartyLoginItemComponent';

export default function ThirdPartyLoginComponent({onClick}) {
  const loginImg = GetLogin();

  return (
    <div className="thirdPartyLogin-container">
      <ThirdPartyLoginItemComponent
        className={`thirdPartyLogin-item`}
        alt={'Line'}
        onClick={() => {
          onClick('line');
        }}
        imgSrc={loginImg.line}
      />
      {/* <ThirdPartyLoginItemComponent
        className={`thirdPartyLogin-item`}
        alt={'Google'}
        onClick={() => {
          onClick('google');
        }}
        imgSrc={loginImg.google}
      />
      <ThirdPartyLoginItemComponent
        className={`thirdPartyLogin-item`}
        alt={'Facebook'}
        onClick={() => {
          onClick('facebook');
        }}
        imgSrc={loginImg.facebook}
      />
      <ThirdPartyLoginItemComponent
        className={`thirdPartyLogin-item`}
        alt={'Tiktok'}
        onClick={() => {
          onClick('tiktok');
        }}
        imgSrc={loginImg.tiktok}
      /> */}
    </div>
  );
}
