import {clientInfo} from '../../resources';
import {customAlphabet} from 'nanoid';
import {store} from '../redux/Store';
import {logIn, token} from '../redux/userSlice';
import {
  LOGIN_ACTION_KEY_SCRIPT,
  executeScriptFunction,
} from './pageSettingHelper';
import {clearReferral} from './helper';
import api from '../api';

export const SIGNIN_MODAL_KEY = 'signIn';
export const ENTERPIN_MODAL_KEY = 'enterPin';
export const SIGNUP_MODAL_KEY = 'signUp';
export const RESETPIN_MODAL_KEY = 'resetPin';
export const SETPIN_MODAL_KEY = 'setPin';
export const LINE_VERIFY_MOBILE_MODAL_KEY = 'lineVerifyMobile';
export const LOGIN_MODAL_KEYS = [
  SIGNIN_MODAL_KEY,
  ENTERPIN_MODAL_KEY,
  SIGNUP_MODAL_KEY,
  RESETPIN_MODAL_KEY,
  SETPIN_MODAL_KEY,
  LINE_VERIFY_MOBILE_MODAL_KEY,
];

//storage key
export const LOGIN_RMB_KEY = 'rmb';

//for verifyMobile page to detect come from which page.
export const VERIFYMOBILE_PREV_PAGE_KEY = {
  login: 'login',
  register: 'register',
  line: 'line',
  forgotPassword: 'forgotpassword',
};

export function LoginWithLine(lineClientId) {
  const url = new URL(clientInfo.lineAuthorize);
  const state = customAlphabet(
    '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
    10,
  )();

  const params = {
    response_type: 'code',
    client_id: lineClientId,
    redirect_uri: `${window.location.origin}/linelogin`,
    state,
    scope: 'profile openid',
  };

  for (const [key, value] of Object.entries(params)) {
    url.searchParams.append(key, value);
  }

  localStorage.setItem('line-state', state);
  window.open(url.toString(), '_top');
}

export function LoginAccount(data) {
  store.dispatch(logIn(data));
  store.dispatch(token(data?.token));
}

export function ContinueJourneyNavigate(navigate) {
  const {from} = store.getState().journey;
  executeScriptFunction(LOGIN_ACTION_KEY_SCRIPT);
  if (from) {
    navigate(`${from}`, {replace: true});
  } else {
    navigate(`/`, {
      replace: true,
    });
  }
}

export function SignUpAndLogin(
  navigate,
  mobile,
  ref,
  password,
  lineUuid,
  bank,
) {
  api.SignUp({
    mobileNumber: mobile,
    referrer: ref,
    password: password,
    lineUuid,
    bank,
    success: async () => {
      clearReferral();
      api.VerifyPassword({
        userId: mobile,
        password: password,
        showErrDialog: false,
        success: () => {
          ContinueJourneyNavigate(navigate);
        },
        error: () => {
          localStorage.removeItem(`verified`);
          navigate('/login');
        },
      });
    },
  });
}
