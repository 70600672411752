import React from 'react';
import './PolicySubSection.scss';

export default function PolicySubSection({title, content}) {
  return (
    <div className="policySubSection-container">
      <div className="policySubSection-title">{title}</div>
      <div className="policySubSection-content">{content}</div>
    </div>
  );
}
