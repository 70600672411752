import moment from 'moment-timezone';
import parser from 'cron-parser';
import {
  createSearchParams,
  matchPath,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Toast from '../components/root/toast/Toast';
import {useModal} from '../context/ModalContext';
import EnterPinModal from '../components/modal/EnterPinModal';
import SignInModal from '../components/modal/SignInModal';
import ChangePassModal from '../components/modal/ChangePassModal';
import {store} from '../redux/Store';
import {
  ENTERPIN_MODAL_KEY,
  LoginAccount,
  SIGNIN_MODAL_KEY,
} from './loginHelper';
import PublicRoutes from '../../pages/routes/PublicRoutes';
import {GetSign_In} from '../../resources/image/Image';
import AuthRoutes from '../../pages/routes/AuthRoutes';
import {JourneyObject, saveJourney} from '../redux/journeySlice';
import api from '../api';
import {VERIFY_GOOGLE_AUTH_MODAL_KEY} from './modalHelper';
import VerifyGoogleAuthModal from '../components/modal/VerifyGoogleAuthModal';
import {setProfile} from '../redux/userSlice';

// import {clientInfo} from '../../resources';

export const SPECIAL_USER = '66000000000000';
export const EXCLUDE_HEADERFOOTER_PAGE = ['/play', '/playgame'];
export const DETECT_FW_PATH = '/game';
export const APP_WRAP_IMG_PATH = '/appwrap';
export function IsSpecialUser() {
  const verify = localStorage.getItem('verified');
  if (verify) {
    return verify == SPECIAL_USER;
  }
  return false;
}

export function IsAppWrap() {
  const isAppWrap = navigator.userAgent.toLowerCase().includes('appwrap');
  return isAppWrap;
}
/**
 * Update session stroage data object
 * @param {storageKey} storageKey session key
 * @param {value} value update value
 */

export function updateObj(storageKey, value) {
  // let prevData = JSON.parse(sessionStorage.getItem('logIn'));
  let prevData = JSON.parse(sessionStorage.getItem(storageKey));

  Object.keys(value).forEach(function (val, key) {
    prevData[val] = value[val];
  });

  // prevData['bank'] = value;

  sessionStorage.setItem('logIn', JSON.stringify(prevData));
}

export function addReferral(refcode) {
  const data = JSON.parse(localStorage.getItem('referral')) || [];

  if (refcode) data.push({iat: Math.floor(Date.now() / 1000), rc: refcode});

  if (data.length) localStorage.setItem('referral', JSON.stringify(data));
}

export function getReferral(expiry) {
  const data = JSON.parse(localStorage.getItem('referral')) || [];

  const found = expiry
    ? data.find(({iat}) => iat + expiry >= Math.floor(Date.now() / 1000))
    : data[data.length - 1];

  return found?.rc;
}

export function clearReferral() {
  localStorage.removeItem('referral');
  localStorage.removeItem('isFromGoogleSEO');
}

export function checkIsFromGoogleSEO(exceptions = []) {
  const referrer = document.referrer;

  const isFromGoogle = referrer.includes('https://www.google.com');
  //  && !exceptions.some((keyword) => referrer.indexOf(keyword));

  if (isFromGoogle) localStorage.setItem('isFromGoogleSEO', isFromGoogle);
}

/**
 * Supports for timezones
 * @param {cron} cron string
 */

export const cronParser = (cron) => {
  const currentTime = new moment();
  const interval = parser.parseExpression(cron);
  const nextDate = new moment(interval.next().toString());
  const duration = moment.duration(nextDate.diff(currentTime));
  const hours = Math.floor(duration.asHours() % 24);
  const minutes = Math.floor(duration.asMinutes() % 60);
  const seconds = Math.floor(duration.asSeconds() % 60);
  return {
    hours,
    minutes,
    seconds,
  };
};

/**
 * @param {func} middlewares
 */
export function Pipeline(...middlewares) {
  const _stack = middlewares;

  function use(...middlewares) {
    _stack.push(...middlewares);
    return this;
  }

  async function go(context = {}) {
    const runner = async (index) => {
      const middleware = _stack[index];

      if (middleware) {
        await middleware(context, () => {
          return runner(index + 1);
        });
      }
    };

    await runner(0);
    return context;
  }

  return Object.freeze({use, go});
}

export const ShowLoginDialog = (useModalContext, state, firstLoad = false) => {
  const {pushModal} = useModalContext;
  const verify = localStorage.getItem('verified');
  const signIn = sessionStorage.getItem('logIn');
  if (verify) {
    if (signIn === null) {
      pushModal({
        key: ENTERPIN_MODAL_KEY,
        content: <EnterPinModal mobile={verify} state={state}></EnterPinModal>,
        showAnim: true,
      });
      return;
    }
  } else if (signIn) {
    const mobile = JSON.parse(signIn).profile.mobileNumber;
    if (mobile !== verify) {
      sessionStorage.clear();
      localStorage.removeItem('verified');
      return;
    }
  }

  if (!firstLoad) {
    pushModal({
      key: SIGNIN_MODAL_KEY,
      content: <SignInModal state={state}></SignInModal>,
      showAnim: true,
    });
  }
};

export const ShowChangePassDialog = (useModalContext, state) => {
  const {pushModal} = useModalContext;
  pushModal({
    // key: SIGNIN_MODAL_KEY,
    content: <ChangePassModal state={state}></ChangePassModal>,
    showAnim: true,
  });
};
//state need to be JourneyObject
export const NavigateToLogin = (navigate, dispatch, state) => {
  if (typeof dispatch == 'function' && state) {
    dispatch(saveJourney(state));
  }
  if (typeof navigate == 'function') {
    navigate('/login');
  }

  // navigate('/login', {state: state})};
};

export const useNav = () => {
  const dispatch = useDispatch();
  const useModalContext = useModal();
  const navigate = useNavigate();
  const anim = JSON.parse(sessionStorage.getItem('anim'));
  const rc = useSearchParams()[0].get('rc') ?? null;

  return (pathname, option) => {
    if (anim) return;
    //everytimes navigate clear all modal
    useModalContext.clearModals();
    if (typeof pathname === 'number') {
      navigate(-1);
    } else {
      const {profile, isLogin} = store.getState().user;
      let finalRc = rc;
      if (profile.referCode) {
        finalRc = profile.referCode;
      }
      const notPublicRoute = !PublicRoutes.some((route) =>
        RouteIsMatch(route.path, pathname),
      );
      const notAuthRoute = !AuthRoutes.some((route) =>
        RouteIsMatch(route.path, pathname),
      );
      if (notPublicRoute && notAuthRoute && !isLogin) {
        const journeyObject = new JourneyObject(pathname);

        dispatch(saveJourney(journeyObject.toPlainObject()));
        navigate(`/login${finalRc ? `?rc=${finalRc}` : ''}`, option);
        // navigate('/login', {
        //   state: {
        //     ...option?.state,
        //     from: pathname,
        //   },
        // });

        // ShowLoginDialog(useModalContext, {
        //   from: pathname,
        //   ...option?.state,
        // });
        return;
      }

      navigate(`${pathname}${finalRc ? `?rc=${finalRc}` : ''}`, option);
    }
  };
};

export const Clipboard = async (text) => {
  var range = document.createRange();
  window.getSelection().removeAllRanges();
  range.selectNode(document.getElementById(text));
  window.getSelection().addRange(range);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
  Toast.show({msg: 'copied'});
};

export const ClipboardByString = async (text) => {
  await navigator.clipboard.writeText(text);
  Toast.show({msg: 'copied'});
};

export function RemoveFWFlag() {
  const flag = JSON.parse(localStorage.getItem('FW'));

  if (flag) {
    flag.shift();
    localStorage.setItem('FW', JSON.stringify(flag));

    if (flag.length <= 0) {
      localStorage.removeItem('FW');
    }
  }
}

export function IsMobileDevice() {
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  return isMobileDevice;
}

export function RouteIsMatch(baseRoute, targetRoute) {
  //extra add in slash at behind, some path with query params will auto have slash behind, so to cater this case, i add in for every path checking
  // const currentSlashBehind =
  //   baseRoute[baseRoute.length - 1] === '/'
  //     ? baseRoute
  //     : baseRoute + '/';
  // const pureTargetRoute = targetRoute.split('?')[0];
  // const targetSlashBehind =
  //   pureTargetRoute[pureTargetRoute.length - 1] === '/'
  //     ? pureTargetRoute
  //     : pureTargetRoute + '/';

  // if (currentSlashBehind === targetSlashBehind) {
  //   return true;
  // }
  // return false;

  const pureTargetRoute = targetRoute.split('?')[0];
  const isMatch = matchPath(
    {
      path: baseRoute,
      exact: true,
      strict: true,
    },
    pureTargetRoute,
  );

  return isMatch ? true : false;
}

export function RouteIsExisted(arrayRoutes, targetRoute) {
  for (let i = 0; i < arrayRoutes.length; i++) {
    const element = arrayRoutes[i];
    if (RouteIsMatch(element, targetRoute)) {
      return true;
    }
  }
  return false;
}

//currentStylePath should start from pages, eg: pages/home/GameListMay23.scss
// export function GetStylePath(currentStylePath) {
//   if (clientInfo.theme === '') {
//     return require('../../' + currentStylePath);
//   }
//   const splitPath = currentStylePath.split('/');
//   const newFilePath = `${clientInfo.theme}/` + splitPath[splitPath.length - 1];
//   splitPath[splitPath.length - 1] = newFilePath;

//   const newJoinedPath = splitPath.join('/');
//   return require('../../' + newJoinedPath);
// }

export function SetCssVariable(key, value) {
  const root = document.documentElement;
  root.style.setProperty(key, value);
}

export function GetLanguageObject() {
  const Sign_In = GetSign_In();

  return (languageKey) => {
    let icon;
    let languageTxt;
    switch (languageKey) {
      case 'th':
        icon = Sign_In.languageFlag.TH;
        languageTxt = 'ภาษาไทย';
        break;

      case 'en':
        icon = Sign_In.languageFlag.UK;
        languageTxt = 'English';
        break;

      default:
        icon = Sign_In.languageFlag.TH;
        languageTxt = 'ภาษาไทย';
        break;
    }

    return {icon, languageTxt};
  };
}

export async function encryptAndStoreObj(key, targetObj) {
  const finalTargetObj = JSON.stringify(targetObj);
  // Generate a random encryption key
  const objectKey = await window.crypto.subtle.generateKey(
    {name: 'AES-GCM', length: 256},
    true,
    ['encrypt', 'decrypt'],
  );

  // Encode the object as text
  const encoder = new TextEncoder();
  const finalTargetObjData = encoder.encode(finalTargetObj);

  // Encrypt the object
  const encryptedData = await window.crypto.subtle.encrypt(
    {name: 'AES-GCM', iv: new Uint8Array(12)},
    objectKey,
    finalTargetObjData,
  );

  // Store the encrypted object and the encryption key
  const encryptedObject = new Uint8Array(encryptedData);
  const keyData = await window.crypto.subtle.exportKey('jwk', objectKey);
  const base64EncryptedObject = btoa(
    String.fromCharCode.apply(null, encryptedObject),
  );

  const encryptedObj = {
    data: base64EncryptedObject,
    key: keyData,
  };
  // Store the encrypted object and encryption key as strings
  localStorage.setItem(key, JSON.stringify(encryptedObj));
}

export async function decryptAndRetrieveObj(key) {
  // Retrieve the encrypted value and encryption key
  const encryptedObj = JSON.parse(localStorage.getItem(key));
  if (!encryptedObj) {
    return null;
  }
  const base64EncryptedValue = encryptedObj.data;
  const keyData = encryptedObj.key;
  const data = atob(base64EncryptedValue.toString());
  const encryptedValue = new Uint8Array(
    data.split('').map((char) => char.charCodeAt(0)),
  );
  // Import the encryption key
  const valueKey = await window.crypto.subtle.importKey(
    'jwk',
    keyData,
    {name: 'AES-GCM'},
    true,
    ['decrypt'],
  );

  // Decrypt the value
  const decryptedData = await window.crypto.subtle.decrypt(
    {name: 'AES-GCM', iv: new Uint8Array(12)},
    valueKey,
    encryptedValue,
  );

  // Decode the decrypted value data
  const decoder = new TextDecoder();
  const value = decoder.decode(decryptedData);

  return JSON.parse(value);
}

export function GetMobileNumberWithoutPrefix(prefix, mobileNumber) {
  const prefixLength = prefix.toString().length;
  return mobileNumber.toString().substring(prefixLength);
}

export function IsOtpRequired(mobile) {
  const verifiedMobile = localStorage.getItem('verified');
  return verifiedMobile != mobile;
}

export function GetPureCountryCode(countryCode) {
  if (!countryCode) return '';
  return countryCode.replace('+', '');
}

export const useVerifyPasswordAndAuth = () => {
  const dispatch = useDispatch();
  const {pushModal} = useModal();

  return ({success, autoLogin = true, googleAuthCancelCB, ...props}) => {
    const successLoginAction = (res) => {
      if (autoLogin) {
        LoginAccount(res.data);
      }
      if (success) {
        success(res);
      }
    };
    api.VerifyPassword({
      ...props,
      autoLogin: false,
      success: (res) => {
        //set profile for verifyGoogleAuth api get the userId
        dispatch(setProfile(res.data));
        if (res?.data?.profile?.enableGoogleAuth ?? false) {
          pushModal({
            key: VERIFY_GOOGLE_AUTH_MODAL_KEY,
            content: (
              <VerifyGoogleAuthModal
                successCB={() => {
                  successLoginAction(res);
                }}
                cancelCB={() => {
                  googleAuthCancelCB?.();
                }}
              />
            ),
            showAnim: true,
          });
        } else {
          successLoginAction(res);
        }
      },
    });
  };
};
