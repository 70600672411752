import React from 'react';
import './rgaNGtcContent.scss';
import PolicyTitle from '../../common/components/text/PolicyTitle';
import PolicySubSection from '../../common/components/text/PolicySubSection';

const RgaNGtcContent = () => {
  return (
    <div className="rgaNGtcContent-container">
      <PolicyTitle text={'RGA and GT&C'} />
      <div className="rgaNGtc-content">
        <PolicySubSection
          title={
            'Responsible Gaming Agreement (RGA) & Gaming Terms and Conditions (GT&C) for services offered by the Organizer of fukuwin.com and Curaçao eGaming to the Player.'
          }
          content={`Registered by Curaçao eGaming on date 18.03.2024.

              EXPLANATION - The Responsible Gaming Agreement (RGA) is a legally binding agreement that outlines the terms and conditions for participating in games of chance offered by the Brand website. The agreement is between the consumer (the player), the gaming service organizer and an intermediary to assist in resolving disputes. By accepting the agreement, you agree to abide by the Gaming Terms and Conditions (GT&C). A simplified explanation is offered with each article, but the actual articles are the only valid source of information for legal purposes.`}
        />
        <PolicySubSection
          title={'RESPONSIBLE GAMING AGREEMENT (RGA)'}
          content={`Article 1 and 2, RGA: Services and terms and conditions

              EXPLANATION - Articles 1 and 2 of the Responsible Gaming Agreement (RGA) explain that by participating in games of chance under this agreement, you understand and accept that you may win or lose. The agreement is accompanied by the Gaming Terms and Conditions (GT&C) and various policies that are integral to the agreement. The operator may also provide additional guidelines that explain how these policies relate to specific in-game offerings, including options to purchase in-game features. It is important to carefully consider these offerings and understand that accepting them binds you to the applicable guidelines. `}
        />
        <PolicySubSection
          title={'Article 1'}
          content={`1.1. This agreement (the: “Responsible Gaming Agreement”, the: “Agreement”, or: “RGA”) sets forth an offer as meant in Article 6:217, Section 1 of the Curaçao Civil Code (“CCC”) to the consumer (“You”, the: “Player”, or the: “End User”) to participate in games of chance (the: “Games”) of which the outcome is determined primarily by random or unpredictable events (the: “Gaming Service”). The Gaming Service is offered via access to a secure and personal area (the: “Gaming Environment”) of the Brand: fukuwin.com (the: “Brand”), which may also include alternate designations (the: “Aliases”). The Gaming Service is meant to be used for Your personal entertainment only. 

              1.2. The Gaming Environment is operated by DG Innovations B.V., a company that has its statutory seat and registered office in Curaçao (the: “Operator”, the: “Gaming Service Organizer”, “Organizer”).
              
              1.3. The Gaming Service is operated under the license 1668/JAZ, issued by the Governor of Curaçao to date 1 October 1996 under the ordinance (the: “Ordinance”) of June 8, 1993 (the: “Gaming License”) of the permanent intermediary Cyberluck Curaçao N.V., a company that has its statutory seat and registered office in Curaçao, trading under the name: “Curaçao eGaming” (the: “Intermediary”). The Minister of Finance of Curaçao has undermandated Stichting Gaming Control Board (the: “Gaming Control Board”), a private foundation that has its statutory seat and registered office in Curaçao, to regulate the Gaming License. 
              
              1.4. Under the Agreement and its GT&C contained herein, You and the Operator may agree under various conditions, elements and factors, to bind an entitlement (the: “Stake”), to a specific and predominantly uncertain and unpredictable outcome of a Game. This activity is defined as: placing a “Bet”, also referred to as: “Wager”. If the outcome of the Game is declared in Your favor (the: “Win”), You shall then be entitled to a benefit, (the: “Prize”). If the outcome of the Bet is not declared in Your favor (the: “Loss”), You shall have forfeited Your Stake.
              
              1.5. In addition to the offerings as set forth in section 1.4., it is at the discretion of the Operator to allow You to be eligible to simultaneously participate in a special, concurrent Game (“Jackpot Game”) of which the outcome may lead to the Win of an accumulated Prize (“Jackpot Win”). In addition to winning a Prize, You may also be eligible to receive a benefit that is gained, not by participating in a Game, but for other reasons, such as to provide You with an incentive (the: “Promotion”). A Promotion is not a Prize. The offer is often of a temporary nature, subject to important restrictions and may be abated by the Operator upon expiration.
              
              1.6. Under the Agreement, the Intermediary is appointed by the Organizer and You to assist in resolving any argument, disagreement, controversy or dissension (a: “Dispute”) pertaining to the Gaming Service, that the Organizer and the Player were not able to amicably resolve amongst themselves, by binding decision as meant in article 7:900, section 2, CCC (the: “Binding Decision”). For this purpose, the Intermediary, the cost to be borne by the Organizer, validates and registers the Brand, the rules and conditions, including changes and updates, that apply to the Gaming Service, making this information available to the Organizer and to You whenever requested (the: “Intermediary Service”). The Intermediary assigns a unique validation reference to each updated version of the Agreement and the GT&C contained herein, to be clearly marked at the top of the registered and validated document.
              
              1.7. The Gaming Service and the Intermediary Service are jointly referred to as the: “Services”). The Organizer and the Intermediary are jointly referred to as the: “Service Parties”. The Player and the Service Parties are jointly referred to as the: “Parties to the Agreement”, or in short, the: “Parties”). 
              
              1.8. The Agreement and the GT&C contained herein is legally binding. Your acceptance of the Agreement and the GT&C contained herein, is a prerequisite for the use of the Services.`}
        />
        <PolicySubSection
          title={'Article 2, RGA'}
          content={`2.1. To the Agreement apply terms and conditions (the: “Gaming Terms and Conditions, or: “GT&C”), that form an integral and inseparable part of the Agreement, as set forth in article 1.2. of the GT&C. They include the policies (the: “Policies”), that are essential for the proper and legitimate use of the Services. 

              2.2. The GT&C contain the following Policies:
              (a)the Policy as set forth in articles 2 and 3, GT&C (the: “Exclusion Policy“);
              (b)the Policy as set forth in articles 4 and 5, GT&C (the: “Account Policy“);
              (c)the Policy as set forth in article 6, GT&C (the: “Withdrawal Policy“);
              (d)the Policy as set forth in article 7, GT&C (the: “Information Policy“);
              (e)the Policy as set forth in article 8, GT&C (the: “Promotions Policy“);
              (f)the Policy as set forth in article 9, GT&C (the: “Anti-Abuse Policy“);
              (g)the Policy as set forth in article 10, GT&C (the: “Dispute Policy“);
              (h)the Policy as set forth in articles 11 and 12, GT&C (the: “Complaint Policy“);
              (i)the Policy as set forth in article 13, GT&C (the: “Liability Policy“);
              (j)the Policy as set forth in article 14, GT&C (the: “Communications Policy“).
              
              2.3. You acknowledge, agree and accept that the Operator may make available for Your perusal, additional details (the: “Guidelines”), further explaining how the Policies relate to specific in-game offerings (the: “In-Game Offerings”), including options to purchase any particular in-game features, such as but not limited to the purchase of free in-game Bets (the: “Features”). 
              
              2.4. You acknowledge, agree and accept that the often temporary nature of the In-Game Offerings makes it impossible to include them within the GT&C, as the GT&C would need to be updated on a weekly or even daily basis. You acknowledge, agree and accept prior to accepting such In-Game Offerings, which are optional, to carefully consider them and when accepting them, that You shall be legally bound to any Guideline that applies to the In-Game Offerings.
              
              2.5. You acknowledge, agree and accept that whenever and wherever You use the Gaming Service, it is Your sole and personal responsibility to make certain that You are not legally prohibited from doing so.
              
              2.6. You acknowledge, agree and accept that: 
              (a)the Agreement, the GT&C contained herein and the Guidelines represent the entire Agreement and understanding between the Parties. It supersedes all prior and contemporaneous agreements and understandings, whether oral or written;
              (b)in the event that any clause or provision of the Agreement and the GT&C contained herein is declared partially void, illegal, or unenforceable by an arbiter or court of competent jurisdiction, its remaining provisions shall remain in full force and effect, and that You shall negotiate in good faith to replace any invalid provision with a valid provision that achieves the same or similar objectives;
              (c)the Service Parties offer You, with reference to the Communications Policy, the opportunity to provide feedback by asking questions, by making remarks or by voicing any concerns pertaining to the Agreement and the GT&C contained herein and the Guidelines.
              
              2.7. The Parties accept, agree and acknowledge that the Agreement and the GT&C contained herein and its performance, including the Guidelines, are subject to the laws of Curaçao, as meant in article 14, paragraph 3, subsection (j) of the License Conditions.`}
        />
        <PolicySubSection
          title={`Article 3, RGA: About the Gaming Environment`}
          content={`EXPLANATION - Article 3 of the Responsible Gaming Agreement (RGA) explains that to participate in the Games, you need to register and create an account, containing a balance that represents your entitlement to credits (Game Credits) and other relevant information for game participation. It is important to understand that not all credits can be converted into real-world money or goods. The operator has the right to modify or terminate the availability, use, or transferability of credits.`}
        />
        <PolicySubSection
          title={`Article 3, RGA`}
          content={`3.1. In order to be able to participate in the Games, You have to obtain access to the Gaming Environment as meant in section 1.1. For this purpose, You are required to register Your credentials. By registering, You are assigned a personal ledger with the Brand (the: “Account”). The Account contains one or more overviews (the: “Credit Balance”, or: “Balance”), displaying Your entitlement to the total amount of credits (“Game Credits” or: “Credits”), as well as other information that might be relevant to You for the purpose of participation in the Games. 

              3.2.  You acknowledge, agree and accept that whether Credits can be considered resources of economic and intrinsic value (“Assets”), depends on the type of Credits:
              (a)Credits that have been purchased by exchanging real-world currency for Credits via a deposit (the: “Deposit”), crediting those Credits to Your Account (“Deposited Credits”), are considered Assets. An exchange that has been compromised by a chargeback, reversal or otherwise, does not qualify as a Deposit;
              (b)Credits obtained through regular gameplay or promotional means are not considered Assets (“Non-Deposited Credits”);
              (c)Non-Deposited Credits may only become Assets if they have been specifically marked by the Operator  as transferable (“Transferable Credits”) into real-world money, tangible goods or services outside the Gaming Environment via means of withdrawal (the: “Withdrawal”). If not, they are to be deemed        non-transferable (“Non-Transferable Credits”).
              
              3.3. You acknowledge, agree and accept that:
              (a)the distinction between Deposited Credits and Non-Deposited Credits is essential;
              (b)You shall abide by the terms and conditions related to each type of Credit as outlined in the Account Policy;
              (c)the Operator retains the right to modify, suspend, or terminate the availability, use, or transferability of both Deposited and Non-Deposited Credits at its sole discretion, in compliance with the Account Policy;
              (d)You should seek legal or financial advice if You have any questions regarding the nature, value, or transferability of Credits before making any decisions related to their usage or disposition.
              
              3.4. In compliance with the Account Policy, the Account may accumulate Credits:
              (a)if You make a Deposit, as meant in section 3.2., subsection (a);
              (b)if You make a Win as meant in section 1.4.;
              (c)if the Operator makes an upward adjustment to the Balance (the: “Adjustment”) due to a:
              (1) given Promotion; 
              (2) given compensation, for example, that is meant to negate a negative customers experience; 
              (3) correction of a previous downward adjustment.
              
              3.5. In compliance with the Account Policy, the Account may dissipate Credits:
              (a)if You make a Withdrawal as meant in section 3.2., subsection (c);
              (b)if You purchase of a Feature (“Feature Purchase”), including, but not limited to the purchase of extra spins (“Free Bets”);
              (c)if You incur a Loss as meant in section 1.4.;
              (d)if the Operator makes a downward adjustment to the Balance due to: 
              (1) an expired Promotion; 
              (2) restrictions and limitations in as set forth in the Account Policy, including but not limited to a 
              correction of an Overbalance as defined in article 4, section 4, GT&C;
              (3) a violation of the Anti-Abuse Policy, as set forth in the Anti-Abuse Policy;
              
              3.6. You acknowledge, agree and accept that the Balance shown only reflects Your entitlement to the total amount of Credits, as meant in section 3.2. (“Total Credit Entitlement”, or: “TCE”). In order to calculate Your entitlement to the total of Withdrawals (“Total Withdrawal Entitlement”, or: “TWE”), the Balance needs to be consolidated in compliance with sections 3.4. and 3.5., resulting in the following equation: “TWE = Deposit Total + Win Total + Total Upward adjustments - Withdrawal Total - Feature Purchases - Loss Total - Total downward adjustments”. You acknowledge, agree and accept that the difference between the Total Credit Entitlement and the Total Withdrawal Entitlement are Non-Transferable Credits as meant in section 3.2., subsection (c).
              
              3.7. You acknowledge, agree and accept that the processing of payments is subject to a continuous process of scrutiny and review by financial institutions and that this may lead to significant delays and restrictions in the processing of Deposits as well as Withdrawals. Such delays may often be outside of the control of the Operator. You acknowledge, agree and accept that the payout of Your Total Withdrawal Entitlement may for this purpose or for other purposes be bound to a schedule of multiple installments (the: “Payout Schedule”), in compliance with the Withdrawal Policy.
              
              3.8. In accordance with article 14, section 2 of the Gaming License, Your acceptance of the Agreement, including the acceptance of the GT&C, is a prerequisite for registering with the Brand and to access the Gaming Environment. If You do not accept the Agreement and the GT&C contained herein, do not register an Account and if you have an existing account You should refrain from using the Gaming Environment. If You have accepted the Agreement and the GT&C but You have changed Your mind and You do not longer intend to comply with it, You have the obligation to immediately contact the Organizer as set forth in the Communications Policy, to discontinue using the Gaming Environment and to close Your account with the Brand, in compliance with the Exclusion Policy.`}
        />
        <PolicySubSection
          title={`Article 4, RGA: Accepting the agreement and its terms and conditions contained herein`}
          content={`EXPLANATION - Article 4 of the Responsible Gaming Agreement (RGA) explains that by either using the gaming environment, or by ticking the acceptance box, you acknowledge and accept the contents of the agreement and the terms and conditions. It also explains the possibilities that are offered to you to review those contents.`}
        />
        <PolicySubSection
          title={`Article 4, RGA`}
          content={`4.1. Either by accessing, registering, or using the Gaming Environment, or by ticking an acceptance box provided for accepting the Agreement and the GT&C contained herein “Acceptance Box”), You signify to acknowledge, agree and accept its contents, and to be legally bound by the Agreement and the GT&C contained herein as well as the Guidelines. This entails, but is not limited to:
              (a)consent to and compliance with the Agreement and the GT&C contained herein;
              (b)consent to and compliance with the Policies and the Guidelines;
              (c)confirmation that You are not deemed an Excluded Person in compliance with the Exclusion Policy;
              (d)representation and warranty that You have the authority to legally bind yourself to the Agreement and the GT&C contained herein and that You are willingly entering into the Agreement for the purposes of no other than personal use and entertainment;
              (e)representation and warranty that You shall refrain from any action, conduct or behavior that violates the Agreement and the GT&C or any Policy or Guideline as meant in the Anti-Abuse Policy (“Abuse”).
              
              4.2. You acknowledge, agree and accept that Abuse as meant in the Anti-Abuse Policy can have serious consequences for everyone involved and that it is important to always conduct Yourself in a responsible and ethical manner. You acknowledge, agree and accept that the Service Parties cannot tolerate any form or type of Abuse and that, if You are found to be involved in any form or type of Abuse, You may face consequences, including but not limited to suspension or termination of Your Account as well as legal action. You acknowledge, agree and accept that complying with the Anti-Abuse Policy is essential in order to protect the rights and interests of not just You, but of everyone involved, using or offering the Services.
              
              4.3. You are required to acknowledge, agree and accept to be legally bound to the Agreement and the GT&C contained herein. Either by accessing, registering, or using the Gaming Environment, You signify to acknowledge, agree and accept  the contents of the Agreement and the GT&C contained herein. For this purpose, the Service Parties shall provide You with a reasonable possibility to review the contents of the Agreement and the GT&C contained herein, as set forth in article 6:233, section (b), CCC, in one or more of the following manners:
              (a)a link to the said contents is presented on the website and together with the Acceptance Box, offering You the possibility to download the Agreement and the GT&C, as meant in article 6:234, section 1, subsection (a), CCC;
              (b)a copy of the Agreement and the GT&C has been validated and registered by the Intermediary, as meant in section 1.6. and it has been made available for Your perusal, as meant in article 6:234, section 1, subsection (b), CCC. 
              4.4. You agree, acknowledge and accept that in addition to the means as set forth in section 4.3.:
              (a)the Agreement and the GT&C has also been made available to You for Your perusal by means of a link to a readable, printable and/or downloadable copy of the Agreement and the GT&C, presented at the bottom of website of the Brand;
              (b)You have the option to at all times verify the legitimacy of the Brand and its license status by clicking on the seal of the Operator. If the seal indicates that the Operator is not properly licensed, stop using the Gaming Services immediately and contact the Intermediary for more information, in compliance with the Communications Policy.
              
              4.5. You agree, acknowledge and accept that any individual option as described in this article, qualifies as a reasonable possibility to review the contents of the Agreement and the GT&C contained herein, as meant in article 6:234, section 1, subsection (a), CCC.
              
              4.6. The original and official version of the Agreement and the GT&C contained herein is written in the English language. You agree, acknowledge and accept that You are solely responsible for making certain that You understand all Services offered under the umbrella of the Agreement and the GT&C contained herein, whether or not with the assistance of others and that You are also bound by the GT&C, as meant in Article 6:232, CCC, even when the Service Parties at the time the Parties entered into the Agreement, understood or ought to have understood that You were not aware of the contents of the GT&C. In addition to the English language, copies may also be available in other languages. However, the original English version shall supersede any translations.
              
              4.7. Prior to and after accepting the Agreement and its GT&C contained herein, the Organizer may perform several additional, visible or covert checks, in a reasonable attempt to provide assurance that You, in compliance with the Exclusion Policy, are indeed eligible to use the Gaming Service. You have a personal responsibility to cooperate. If You are not eligible, or if You think that You might not be eligible, You should under no circumstance register an Account and You should refrain from using the Gaming Environment and the Gaming Services.`}
        />
        <PolicySubSection
          title={`Article 5, RGA: Updates to the agreement and its terms and conditions`}
          content={`EXPLANATION - Article 5 of the Responsible Gaming Agreement (RGA) states that the service providers have the right to change the agreement and its terms and conditions. Any modifications or updates will be made available for you to review on the website. The updated agreement will replace previous versions and become effective two weeks after being made available. It is the consumer's responsibility to regularly check for changes. By continuing to use the services after the updated terms become effective, the consumer is considered to have accepted the changes. However, updates to the guidelines for in-game offerings may not be subject to the same terms, and it's important to review them regularly as well. Using an in-game offering after an updated guideline is considered acceptance of the updated guideline.`}
        />
        <PolicySubSection
          title={`Article 5, RGA`}
          content={`5.1. You acknowledge, agree and accept that the Service Parties reserve the right to modify, update, or change the Agreement and the GT&C herein at their discretion. Any modifications, updates or changes in the Agreement and the GT&C herein shall be made available for Your perusal as set forth in section 4.3.

              5.2. You acknowledge, agree and accept that the updated Agreement and the GT&C herein shall supersede and replace all prior versions, and shall become effective two weeks after they have been made available as meant in section 5.1. (the: “Effective Date”). 
              
              5.3. You acknowledge, agree and accept that It is Your responsibility to regularly review the Agreement and the GT&C contained herein. Continued use of the Services after the Effective Date of the updated terms and conditions constitutes acceptance of the updated Agreement and the GT&C contained herein.
              
              5.4. You acknowledge, agree and accept that due to the often temporary nature of the In-game Offerings as meant in sections 2.3. and 2.4., any updates to the Guidelines as meant in section 2.3. shall be exempt and not subject to the terms and conditions of this article. It is Your responsibility to regularly review the Guidelines. Continued use of an In-Game Offering to which a Guideline applies, constitutes acceptance of the updated Guideline. `}
        />
        <PolicySubSection
          title={`Article 6, RGA: Non-transferability`}
          content={`EXPLANATION - Article 6 of the Responsible Gaming Agreement (RGA) states that due to the personal nature of the gaming service, you understand and accept that any rights or claims you have related to the services provided under the agreement cannot be transferred or assigned to any third party. Any attempt to transfer these rights or claims will be considered a violation and will be null and void.`}
        />
        <PolicySubSection
          title={`Article 6, RGA`}
          content={`6.1. You acknowledge, agree and accept that You are personally bound to any rights or claims arising from or relating to the Services performed under the Agreement and the GT&C contained herein, regardless of the legal argument and form of action, whether in contract, tort, strict liability or otherwise. Therefore such rights or claims shall not be assignable or transferable, in whole or in part, to any third party as meant in section 3:83, subsection 2, CCC. 

              6.2. You acknowledge, agree and accept that, in addition to section 6.1., any rights or claims arising from or relating to the Services performed under the Agreement and the GT&C contained herein shall not be assignable or transferable, in whole or in part, to any third party as meant in section 3:83, subsection 1, CCC, as article 2 of the Ordinance, with reference to articles 15 and 16 of the Gaming License requires a personal relationship between You and the Organizer. As such, the nature of this relationship prohibits such rights or claims from being assignable or transferable.
              
              6.3. You acknowledge, agree and accept that any attempted assignment or transfer in violation of this article shall be deemed Abuse as set forth in the Anti-Abuse Policy and that it shall be null and void. 
              
              6.4. You acknowledge, agree and accept that rights or claims of rights cannot be passed on to third parties to provide a disadvantage or an advantage as set forth in article 6:163, CCC.
              
              6.5. This article shall survive the termination or expiration of the Agreement and its GT&C contained herein.`}
        />
        <PolicySubSection
          title={`Article 7, RGA: Choice of Forum`}
          content={`Article 7 of the Responsible Gaming Agreement (RGA) states that any conflicts or disagreements related to the agreement, including breaches or terminations, will, upon notification, be resolved through arbitration. You have the option to opt-out of arbitration by sending a written notice within one month, after notification. If you choose not to exercise that option, you will be bound by that choice.`}
        />
        <PolicySubSection
          title={`Article 7, RGA`}
          content={`7.1. You acknowledge, agree and accept that any conflict, argument, disagreement, dissension, discord, challenge, controversy or claim arising out of or related to Agreement and the GT&C herein, including the Guidelines, including but not limited to the breach, termination, or validity thereof, including requests for the annulment of a decision as set forth in article 12, section 5 of the GT&C, regardless of the form of action, whether in contract, tort, strict liability or otherwise, shall be resolved by means of arbitration before the London Court of International Arbitration (“LCIA”), in accordance with the rules that are in force at the time the arbitration is commenced. 

              7.2. You acknowledge, agree and accept that the number of arbitrators with the LCIA shall be three and the seat, or legal place, of arbitration shall be London, England. The language to be used in the arbitration proceedings shall be the English language. The decision of the arbitrators shall be final and binding on the Parties and may be enforced in any court of competent jurisdiction.
              
              7.3. You acknowledge, agree and accept that: 
              (a)You may elect to opt-out of arbitration as set forth in sections 7.1. and 7.2. by sending written notice by email to the Intermediary of Your declaration to opt-out, within a period of one calendar month commencing on the day after any of the Service Parties has first notified You that it intends to invoke the arbitration clause due to a matter as meant in section 7.1.; 
              (b)if such notice as meant under (a) is sent, or if section 7.1. is deemed invalid or unenforceable, Parties shall submit the matter to the exclusive jurisdiction of the Court, located in London, England. You may elect to opt-out of the choice of that court, located in London as well, in the same manner as set forth in  subsection (a);
              (c)if section (b) is deemed invalid or unenforceable, Parties shall submit the matter to the exclusive jurisdiction of the court, located in Your country of residence.
              
              7.4. You acknowledge, agree and accept that arbitration is offered to provide the Parties with a specialized, faster and lower-cost alternative to litigation.
              
              7.5. You acknowledge, agree and accept that, as set forth in section 7.3., if You choose not to exercise any of the rights, You shall then have waived, on the penalty of inadmissibility, any right to challenge the jurisdiction of a panel of arbitration or court and that You shall be bound by its decision. 
              
              7.6. This article shall survive the termination or expiration of the Agreement and its GT&C contained herein.
              
              
              
              EXPLANATION - The Gaming Terms and Conditions (GT&C) are an important part of the Responsible Gaming Agreement. They explain rules and policies that you need to follow when using the gaming service. This includes things like who can use the service, how to exclude yourself if needed, and how to protect your account. It's important to understand and agree to these terms before using the service. `}
        />
        <PolicySubSection
          title={`GAMING TERMS AND CONDITIONS (GT&C)`}
          content={`EXPLANATION - Article 1 of the Gaming Terms and Conditions (GT&C) explains that the GT&C are inseparable from the Responsible Gaming Agreement (RGA).`}
        />
        <PolicySubSection
          title={`Article 1, GT&C`}
          content={`1.1. These terms and conditions (the: “Gaming Terms and Conditions” or: “GT&C”) apply to the Agreement (the: “Responsible Gaming Agreement”, or in short: the: “Agreement” or: “RGA”). 

              1.2. These GT&C form an integral and inseparable part of the Agreement and acceptance of the Agreement and the GT&C contained herein is a prerequisite for the use of any of the services as mentioned in the Agreement, with reference to article 1, subsection 8 and article 3, subsection 8 of the Agreement.
              
              
              Exclusion Policy: Are You eligible to use the Gaming Service?
              
              EXPLANATION - Articles 2 and 3 of the Gaming Terms and Conditions (GT&C) explain the Exclusion Policy, which means certain people are not allowed to use the service. If you fall into any of the excluded categories, such as being underage, having gambling problems, or being involved in fraud, you cannot use the service. The text also mentions the option to voluntarily exclude yourself from using the service if you feel you cannot gamble responsibly. If you request self-exclusion, the operator will temporarily suspend or terminate your account, and you may not be able to reverse the exclusion for at least one month.`}
        />
        <PolicySubSection
          title={`Article 2, GT&C`}
          content={`2.1. This article as well as article 3, outlines the Exclusion Policy as meant in article 2, section 2, subsection (a) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Exclusion Policy and that You shall comply with the Exclusion Policy.

2.2. You acknowledge, accept and agree that gambling is not for You if You are deemed an excluded person, as defined in section 2.3. (“Excluded Person”). Any use of the Gaming Service by an Excluded Person shall be deemed null and void.

2.3. In addition to article 3 of the Gaming License, as an Excluded Person is qualified, any person meeting one or more of the following qualifications:
(a)a resident of Curaçao;
(b)a Vulnerable Person as meant in section 2.4.; 
(c)a person who is Self-Excluded as meant in article 3;
(d)a person who has not accepted to be legally bound to the Agreement and these GT&C contained herein;
(e)a person who is an additional member of a Household as meant in section 4.6. of the Account Policy;
(f)a person that is not a natural individual, such as, however not limited to a company or a foundation;
(g)a person who is acting on behalf of someone else, as an agent, by misrepresentation or otherwise in using any services offered under the Agreement;
(h)a person who is engaged in selling, buying, or otherwise transferring the value of any gaming or gambling related accounts to or from other persons;
(i)a person who is directly or indirectly employed or otherwise engaged in the offering of gaming services;
(j)a person who is in the position to influence the outcome of a game of chance in which that persons participates, including but not limited to a manager or referee involved in the organization of a sports event;
(k)a family member of any person as mentioned under (g), (h), (i), or (j);
(l)a person who has committed an act of Material Fraud as set forth in the Anti-Abuse Policy.

2.4. Defined as a “Vulnerable Person” is any natural individual who:
(a) has not yet reached the age of eighteen years; or:
(b) is not legally capable of entering into binding agreements; or:
(c) termination of the Account of the Player with the Brand due to problematic gambling behavior.

2.5. A person who has been Excluded due to an act of Material Fraud as implied in section 2.3., subsection (l)  of this article, shall remain Excluded for a minimum period of five years.

2.6. A “Restricted Person” is any natural individual that has been partially restricted by the Operator to make use of certain elements of the Gaming Service in connection with any type of problematic gambling behavior, including but not limited to Abuse as set forth in the Anti-Abuse Policy. A person who has been Restricted, shall remain Restricted for a minimum period of one calendar month. Restrictions may include, but are not limited to prohibitions to play certain Games, or prohibitions to use Promotions, as defined in the Promotions Policy. 

2.7. You acknowledge, accept and agree that if You become an Excluded Person or a Restricted Person while using the Gaming Service, You shall then henceforth incur the exclusions and restrictions in compliance with the Exclusion Policy.`}
        />
        <PolicySubSection
          title={`Article 3, GT&C`}
          content={`3.1. This article belongs to the Exclusion Policy. It outlines the possibility to voluntarily exclude Yourself from using the Gaming Service. (“Self-Exclusion”). It is aimed at assisting You in managing Your gambling behavior. You acknowledge, agree and accept to Self-Exclude Yourself whenever You feel that You are not able to use the Game Service in a responsible manner. You give consent to and shall comply with the procedures for Self-Exclusion as set forth in this article.

3.2. You acknowledge, agree and accept that the Operator shall temporarily suspend access to Your Account or terminate it altogether, within two business days, deemed Monday through Friday, excluding public holidays (“Business Days”) of receiving a properly formatted request to Self-Exclude in connection with Your declaration that You may be experiencing problematic gambling behavior and that request having been fully validated. (“Self-Exclusion Request”)

3.3. You acknowledge, agree and accept that a Self-Exclusion Request as meant in section 3.1. is deemed properly formatted if and when it complies with the criteria as set forth in section 3.4. If Your request does not comply with the said criteria, for example, if it would not clearly mention that Your request has to do with problematic gambling behavior, the Operator is not required to treat the request as a request for Self-Exclusion and the Self-Exclusion Policy shall then not apply to Your request.

3.4. You acknowledge, agree and accept that the procedure for Self-Exclusion with the Brand is as follows: If You want to be Self-Excluded from fukuwin.com, please contact us on the following email address: admin@dginnovations.info. Your email needs to include the following information:
a)a clear request that states that You wish to be fully Self-Excluded (not partially restricted) on a voluntary basis;
b)the duration of the period of the Self-Exclusion, with a minimum of three months, up to ﬁve years;
c)Stating that Your Request has to do with problematic gambling behavior;
d)Providing a copy of your ID and Proof of Address.

3.5. You acknowledge, agree and accept that You shall remain solely responsible for all activity on Your Account during the time that Your request for Self-Exclusion may be pending, within a maximum time frame as meant in section 3.2. During the said time frame, the Operator is not obligated to void any activities on Your Account, such as but not limited to placed Bets and incurred Losses on the basis of the argument that You have a request pending for Self-Exclusion. 

3.6. Although a request for Self-Exclusion shall not necessarily by itself qualify You as a Vulnerable Person as meant in section 2.4., subsection (c), it does obligate the Operator to conduct a further investigation into Your personal situation, as meant in the said subsection. You acknowledge, agree and accept that the outcome of the said investigation may be that You shall indeed be qualified as a Vulnerable Person in compliance with the Exclusion Policy. As a result You would no longer be eligible to keep Your Account.

3.7. A state of Self-Exclusion may only be reversed upon Your properly formatted request to the Operator, if and when it complies with the criteria as set forth in section 3.4. You may request the Operator to reverse the Self-Exclusion, not earlier than three months have expired since the Self-Exclusion came into effect. Whether the Operator grants a request to reverse a state of Self-Exclusion, is at the discretion of the Operator.

3.8. The Operator is obligated to investigate excessive numbers of requests to reopen or close an Account into potential Abuse of Self-Exclusion. The outcome of such an investigation may lead to temporary restrictions to access Your Account, or it may lead to a permanent Exclusion as set forth in the Exclusion Policy.

3.9. You acknowledge, agree and accept that the Operator can only offer You the possibility of Self-Exclusion if You already have an existing Account. It is technically impossible for the Operator to Self-Exclude persons who are not known to the Operator as Curaçao does not currently offer a national registry of Excluded Persons. You agree that any personal data necessary to prevent You from accessing any gaming related services may for future reference be released to the Intermediary and the Gaming Control Board for the purpose of compiling such a registry. You furthermore agree that non-personal data shall be released on a quarterly basis to the Gaming Control Board for purposes of oversight.


Account policy

EXPLANATION - Articles 4 and 5 of the Gaming Terms and Conditions (GT&C) explain the Account Policy for using a gaming service. That policy states that you must keep your account details confidential and not share them with anyone. The policy also mentions that there are restrictions and limitations on your account for your own protection, and that you are responsible for keeping track of your activities and any limits set. If you have multiple accounts within the same household, it may lead to access issues and potential violations of the policy. Lastly, your account can be temporarily suspended in accordance with certain policies.`}
        />
        <PolicySubSection
          title={`Article 4, GT&C`}
          content={`

Article 4, GT&C
4.1. This article as well as article 5, outlines the Account Policy as meant in article 2, section 2, subsection (b) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Account Policy and that You shall comply with the Account Policy.

4.2. You acknowledge, agree and accept to keep the details of Your Account confidential and that You shall not provide such details to anyone.

4.3. You acknowledge, agree and accept that:
a)daily restrictions and limitations are imposed to Your Account, as set forth in article 5, notwithstanding additional restrictions and limitations that were imposed to You as a personal measure; 
b)these restrictions and limitations have been imposed in accordance with articles 14 and 15 of the Gaming License. They are there for Your own protection and they cannot be the subject of negotiation. Additional and bespoke restrictions and limitations may be applied to Your Account as well. The Service Parties, including customer helpdesk representatives, are never authorized to exempt You from these restrictions and limitations in any way, shape or form.

4.4. You acknowledge, agree and accept that certain of Your activities, such as the placing of multiple Bets, receiving temporary Promotions such as Free Bets may lead to a situation, outside of the control of the Operator, in which a maximum value or threshold is exceeded (“Excess”). You have a personal responsibility to keep track of this. Even if Your Balance displays a figure exceeding a maximum value or threshold (“Overbalance”), You are bound to the calculations as set forth in article 3 of the RGA. You acknowledge, agree and accept that the Organizer is allowed to at any time correct any Excess, with reference to article 15, section 4 of the Gaming License.

4.5. You acknowledge, agree and accept that the Gaming Service shall be denied to all but one member within Your household as defined in section 4.6. (the: “Household”), as the Organizer would not be able to adequately monitor access to the Gaming Environment of multiple Accounts within such Household. It therefore cannot be ruled out that by allowing multiple Accounts, Excluded Persons within the Household may gain access to the Gaming Service.

4.6. You accept that as a member of Your Household is deemed, any natural individual who:
a)frequently shares common living arrangements, such as however not limited to friends, family and neighbors; or:
b)frequently shares any kind of equipment to access the Gaming Service such as however not limited to smartphones, tablets and desktop computers; or:
c)is likely to be a member of Your Household as meant in subsections (a) or (b) and is claiming to indeed be such a member.

4.7. You acknowledge, agree and accept that You have the sole personal responsibility to make certain at the time of registration of Your Account, that no other person within Your Household has already registered with the Brand. If You were to register such a multiple account, this shall constitute a violation of the Anti-Abuse Policy and the Service Parties shall be allowed to take any appropriate matters to stop the Abuse, with reference to section 9.6. If You cannot guarantee that no members of Your Household have already registered with the Brand, do not register an Account Yourself and refrain from using the Gaming Service.

4.8. You acknowledge, agree and accept that Your Account may be temporarily suspended in compliance with section 7.7. of the Information Policy or the Anti-Abuse Policy. `}
        />
        <PolicySubSection
          title={`Article 5, GT&C`}
          content={`5.1. This article belongs to the Account Policy. It outlines the specific limitations and restrictions as determined by the Operator. You give consent to and shall comply with the limitations and restrictions as set forth in this article.

5.2. You acknowledge, agree and accept that the total of daily Wins, as deﬁned in article 1, section 4 of the RGA, excluding Jackpot Wins, the days to be determined in accordance with Atlantic Standard Time is bound to a maximum value, calculated in the following currency: Euro (the: Daily Win Limit” or: “DWL”). Any value exceeding the Daily Win Limit is deemed Excess (or: the existence of an Overbalance) as set forth in article 4, section 4. The Operator is obligated to correct the Overbalance, as set forth in article 3, section 5, subsection (d) 2 of the RGA, so that it equals the Daily Win Limit, at the latest with any Withdrawal.

5.3. When calculating the Daily Win Limit, the following factors are taken into consideration:
(a) the total of Wins and Losses on a daily basis for the period of one month (the “Rolling Month”) prior to the Win date (the: “Result”);
(b) the total of Deposits for the Rolling Month prior to the Win (the: “Deposit Sum”);

5.4. The Daily Win Limit is determined as the lesser value of any of the following calculations:
(a) DWL1 = 5,000 Euro
(b) DWL2 = Deposit Sum x 10

5.5. If the Daily Win Limit is restricted on the basis of article 5 section 4, subsection (b), any Deposits made on the day of the Win prior to that particular Win are to be included in the Deposit Sum. You acknowledge, agree and accept that Deposits made on the day of the Win but after the Win itself are not included.

5.6. You acknowledge, agree and accept that:
(a) Jackpot Games may be offered across different brands and operators. Individual operators may therefore introduce different limits and restrictions to a Jackpot Win;
(b) the difference between a regular game and a Jackpot Game is essential and it is important to carefully consider and understand limitations and restrictions that apply to Jackpot Wins;
(c) the Operator has determined the Jackpot Win limitations and restrictions for the Brand as set forth in article 5 section 7.

5.7. To be calculated separately from and not inﬂuenced by the Daily Win Limit, the total of daily Jackpot Wins, as deﬁned in article 1, section 5 of the RGA is bound to a maximum value, calculated in the following currency: Euro (the: Jackpot Win Limit” or: “JWL”), JWL = 10,000 Euro.


Withdrawal Policy

EXPLANATION - Article 6 of the Gaming Terms and Conditions (GT&C) explains the Withdrawal Policy. The policy mentions that the possibilities for payment processing under the Curaçao banking system is limited and it may therefore be facilitated in cooperation with a third party in another country. It also states that your withdrawals may be temporarily withheld or divided over time for information collection and review purposes. Additionally, it clarifies that no interest, banking expenses, or compensation for currency rate fluctuations will be provided for withdrawals.`}
        />
        <PolicySubSection
          title={`Article 6, GT&C`}
          content={`6.1. This article outlines the Withdrawal Policy as meant in article 2, section 2, subsection (c) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Withdrawal Policy and that You shall comply with the Withdrawal Policy.

6.2. You acknowledge, agree and accept that the possibilities to process payments under the Curaçao banking system are limited. You acknowledge, agree and accept that payments shall be executed by and under the responsibility of the Operator, with reference to article 1, section 2 of the Agreement and in compliance with this Policy and the Liability Policy.

6.3. You acknowledge, agree and accept that Your Withdrawals may be temporarily withheld, limited or divided over a period of time pending the collection pending the collection and review of information as meant in section 7.7 of the Information Policy.

6.4. You acknowledge, agree and accept that no interest, third party banking expenses or compensation for currency rates shall be due on any Withdrawals in connection with the Balance, Deposits, Prizes, Jackpots, Promotions, or rights to claims of any kind, regardless of the legal argument and regardless of their form, whether in contract, tort, strict liability or otherwise. You acknowledge, agree and accept to waive any entitlement to interest on any amount. You acknowledge, agree and accept that, unless determined otherwise by and at the discretion of the Organizer, the value of any claim is calculated and paid out in currency or asset as set forth in section 6.5. You acknowledge, agree and accept to waive any entitlement to compensation for fluctuations in value of any currencies or assets.

6.5. You acknowledge, agree and accept that, notwithstanding other restrictions and limitations as set forth the the Agreement and the GT&C contained herein, any individual Withdrawal, as defined in article 3, section 2, subsection (c) of the Agreement is bound to a maximum value (the: “Withdrawal Limit”), taking into consideration the total of Withdrawals for one Rolling Month prior to the day of the request of the Withdrawal, including any Withdrawals made on the same day prior to the Withdrawal (the: “Withdrawal Sum”). The days to be determined in accordance with Atlantic Standard Time. The values calculated in the following currency: Euro.

6.6. The Withdrawal Limit is determined on the basis of the following calculation: Withdrawal Limit = 5,000 Euro


Information Policy

EXPLANATION - Article 7 of the Gaming Terms and Conditions (GT&C) explains the Information Policy. The policy mentions that you need to provide various types of information, including basic details like your address and date of birth, as well as additional information requested by the service providers. It also states that you must cooperate with any requests for electronic verification of your documents and identity, and failure to provide information or be honest may be considered as abuse and have legal consequences. Additionally, it explains that your account may be temporarily suspended while information is being collected and reviewed, such as in cases of discrepancies, changes in your gaming behavior, or suspicions of policy violations.`}
        />
        <PolicySubSection
          title={`Article 7, GT&C`}
          content={`7.1. This article outlines the Information Policy as meant in article 2, section 2, subsection (d) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Information Policy and that You shall comply with the Information Policy.

7.2. You acknowledge, agree and accept to timely and fully cooperate with each information request and that it is at the sole discretion of the Service Parties to determine whether the information provided is of sufficient quantity or quality.

7.3. You acknowledge, agree and accept that in addition to basic and essential information, such as Your home address, email address, personal telephone number and date of birth, a substantial amount of information of a reasonable quality is required after registering Your Account, to allow You to make full use of Your Account.

7.4. You acknowledge, agree and accept to timely and fully cooperate with each request to participate in an electronic verification process offered by a third party that is specialized in verifying documents and identities, especially in the event of suspicions of Abuse as meant in article 9.

7.5. You acknowledge, agree and accept that especially in the early stages of using the Gaming Service, it may not yet be possible for the Organizer to establish a full and complete picture of how You might behave as a Player and that this may lead to adjusted conclusions and additional reviews after You have already registered Your Account. You therefore have a continuous obligation to provide the Service Parties with any requested information.

7.6. You acknowledge, agree and accept that not complying with the obligation to provide information, not being honest, may be qualified by the Service Parties as Abuse in compliance with the Anti-Abuse Policy and that legal ramifications may be involved.

7.7. You acknowledge, agree and accept that Your Account may be temporarily suspended in compliance with the Account Policy and the Withdrawal Policy, pending the collection and review of information. Such situations include, however, are not limited to: 
(a)discrepancies in information, including but not limited to misspelled names, an improperly formatted date of birth or minor inconsistencies in passports or payment information;
(b)changes in Your use of the Gaming Service;
(c)information of sub-par quality;
(d)a reasonable suspicion of a violation of the Anti-Abuse Policy;
(e)in the event of excess as set forth in section 4.4., including but not limited to exceeding a maximum threshold.


Promotions Policy

EXPLANATION - Article 8 of the Gaming Terms and Conditions (GT&C) explains the Promotions Policy. Promotions are temporary offers like sales, coupons, gifts, and special events. When accepting a promotion, you need to read and understand the specific rules and conditions attached to it. You have the responsibility to make sure that using a promotion does not exceed certain limits. If there is any abuse of a promotion, the operator may take measures and void any benefits associated with it.`}
        />
        <PolicySubSection
          title={`Article 8, GT&C`}
          content={`8.1. This article outlines the Promotions Policy as meant in article 2, section 2, subsection (e) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Promotions Policy and that You shall comply with the Promotions Policy.

8.2. Within the context of the Agreement and the GT&C contained herein, a “Promotion” is any type of temporary offer that is meant to interest You in using the Gaming Service, such as however not limited to sales, coupons, gifts, Free Bets, temporary contests, special events and loyalty programs. When accepting any type of Promotion, You have a responsibility to read, understand and accept any specific Rules and Conditions attached to that offer, for which You are offered a reasonable opportunity prior to accepting such offer. 

8.3. You have a choice to either accept or to not accept a Promotion. However, when You accept the offer for a Promotion, You acknowledge, agree and accept any Guidelines that are bound to the use of such Promotion.

8.4. You acknowledge, agree and accept that a Promotion is not deemed an Asset unless explicitly determined by the Operator in compliance with the Account Policy.

8.5. You acknowledge, agree and accept the restrictions and limitations as set forth in the Account Policy apply to all Promotions. You acknowledge, agree and accept that it is Your sole and personal responsibility to make certain that the use of any Promotion does not result in Excess as meant in section 4.4. of the Account Policy. If Excess would nevertheless occur, the Operator is obligated to make corrections, in compliance with the Account Policy.

8.6. You acknowledge, agree and accept that the following shall apply, at any time, to any type of Promotion and that it shall apply, even if You are of the opinion that the Promotional offer was not sufficiently clear or it was sufficiently clear, but You did not or not sufficiently understand it:
(a)Under the Promotions Policy:
(1) a Promotion may only be used within the specific Game in which it was offered, unless explicitly stated otherwise in the offer to use such Promotion;
(2) any Promotion is offered on a temporary basis and the option to use it is temporary. If You have accepted to make use of a Promotion, but it nevertheless remains unused, it shall expire one week after You have received such Promotion. Depending on the details of the Promotion, it may expire even sooner, but never later;
(b)Under the Account Policy:
(1)unused Free Bets are not Assets and they are Non-Transferable; 
(2)the Operator is entitled to remove Free Bets from Your Account, after they have expired;
(3)if the outcome of a used Free Bet results in a Win, You are entitled to that Win only, not to the value of the Free Bet itself;
(4)the use of a Promotion is limited to only one natural individual, who is allowed one Account and who is the only member of a Household using the Gaming Service; 
(c)Under the Exclusion Policy:
(1) an Excluded Person is never entitled to the use of a Promotion. The Operator is legally obligated to void and remove any benefits connected to a Promotion that was awarded to an Excluded Person, including Wins;
(2) a Restricted Person may not be entitled to the use of a Promotion. The Operator shall void and remove any benefits connected to a Promotion of which its use was restricted, including Wins;

8.7. You acknowledge, agree and accept that Abuse of a Promotion shall obligate the Operator, in addition to other measures, to void any benefits or entitlements in connection with the use of that Promotion, as set forth in the Anti-Abuse Policy.


Anti-Abuse Policy

EXPLANATION - Article 9 of the Gaming Terms and Conditions (GT&C) explains the Anti-Abuse Policy. Abuse includes actions like providing false information, creating multiple accounts, using someone else's payment option, or attempting to trade rights of claim. If you engage in abuse, the service providers may take actions such as removing benefits, voiding credits, or terminating the agreement. There may also be penalties imposed, calculated based on the value of your deposits, if fraud or material fraud is involved.`}
        />
        <PolicySubSection
          title={`Article 9, GT&C`}
          content={`9.1. This article outlines the Anti-Abuse Policy as meant in article 2, section 2, subsection (f) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Anti-Abuse Policy and that You shall comply with the Anti-Abuse Policy.

9.2. You acknowledge, agree and accept to report any bugs, errors, vulnerabilities, or weaknesses to the Service Parties and to refrain from exploiting them for Your personal gain or for those of others, as this would qualify as Abuse. Although the Service Parties are committed to the prevention of bugs, errors, vulnerabilities, or weaknesses, technical, legal, financial or otherwise, it cannot be guaranteed that the occasional error or mistake may never occur. The Services Parties are allowed to correct any errors or mistakes in compliance with any Policy.

9.3. Notwithstanding other conducts or actions that may qualify as Abuse, You acknowledge, agree and accept that the following behavior, individual acts or conducts, or combined with other the equal or different types of acts or conducts by others, shall be deemed Abuse:
(a)the act or conduct of misrepresentation of the identity by presenting any type of false information and or by the withholding of information;
(b)the opening of multiple Accounts under Your name or another name by misrepresentation of the identity, or allowing a neighbor to share any kind of equipment to use the Gaming Service or access the Gaming Environment;
(c)using a payment option that is under the name of another person, or to involve persons to receive payments on Your behalf, or acting as an agent on behalf of someone else; 
(d)reversing, canceling or making chargebacks to payments;
(e)manipulating the outcome of a sports or competitive event in an unfair and predetermined manner (“Match Rigging”);
(f)manipulating prices of in-game items, virtual currencies, or other gaming assets (“Price Manipulation”);
(g)the multiple use of a one-time Promotion that was meant to be used by one person only on a one-time basis;
(h)acts designed to conceal a source of funds (“Money Laundering”)
(i)attempts at trading rights of claim with another person as set forth in article 6 of the Agreement;
(j)violation of any pledge, oath, promise, law, directive, code of conduct or Guideline;
(k)any attempt at misconduct that would be deemed Abuse under the Anti-Abuse Policy.

9.4. Within the context of the Agreement and the GT&C contained herein:
(a)“Fraud” is defined as an aggravated form of Abuse with an intentional misconduct (“Intent”) that is designed to cause harm for the purpose of gaining an unfair or unlawful advantage, gain or benefit, such as, but not limited to misrepresentation or the concealment of fact;
(b)“Material Fraud” is defined as an aggravated form of Fraud of which the Intent is significant enough to, on the balance of probability, have an impact on the continuity of any of the Service Parties, such as, but not limited to the Intent to structurally exploit a bug, error, vulnerability, or weakness, technical, legal, financial or otherwise as meant in section 9.2.

9.5. You acknowledge, agree and accept that Abuse shall be deemed Fraudulent or Materially Fraudulent, if, on the balance of probability, there is evidence of Intent.

9.6. You acknowledge, agree and accept that the Service Parties may take legal action to recover any losses or damages caused by any form of Abuse, such as, but not limited to:
(a)removing from Your Balance or voiding any benefits that were obtained by Abuse;
(b)voiding all Transferable and, or Non-Transferable Credits;
(c)establishing Your personal liability for any associated costs or penalties, such as but not limited to third-party costs incurred by Abuse connected to cross-betting or violation of the Account Policy; 
(d)terminating the Agreement with immediate effect, without liability, in the event of Fraud or Material Fraud.

9.7. You acknowledge, agree and accept that, notwithstanding the possibility of the Service Parties to take the said actions as set forth in section 9.6., You may, at the discretion of the Operator, incur a penalty (the “Abuse Penalty”) over the value of the total of Your Deposits as meant in article 3, section 2, subsection (a) of the Agreement:
(a)to be calculated at ten percent in the event of Fraud, with a minimum of one hundred euro and a maximum of one thousand euro;
(b)to be calculated at one hundred percent in the event of Material Fraud.

9.8. You acknowledge, agree and accept that the Abuse Penalty may be imposed as follows:
(a)by a downward adjustment of the Balance as meant in article 3, section 4, subsection (d) of the Agreement;
(b)by any immediately collectible claim of right.

9.9. The failure of the Service Parties to take any action in response to a breach or default by the Player shall not be deemed a waiver of any subsequent breach or default. Any delay in exercising any right or remedy shall not operate as a waiver of that right or remedy, and shall not preclude the Service Parties from later exercising that right or remedy.


Dispute Policy

EXPLANATION - Article 10 of the Gaming Terms and Conditions (GT&C) explains the Dispute Policy. If there is a misunderstanding between you and the organizer, you should try to resolve it with them by clearly explaining your position, giving each other time to respond, and avoiding escalations. If the issue becomes a dispute, you should inform the organizer about it, explaining the problem, your disagreement, and what you consider to be a fair solution. The organizer will attempt to resolve the dispute if it's properly explained and brought to their attention within one year, and they will keep the necessary information for up to two years.`}
        />
        <PolicySubSection
          title={`Article 10, GT&C`}
          content={`10.1. This article outlines the Dispute Policy as meant in article 2, section 2, subsection (g) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Dispute Policy and that You shall comply with the Dispute Policy.

10.2. You acknowledge, agree and accept that You have a responsibility to prevent an initial misunderstanding, mistake or error from becoming a disagreement between You and the Organizer on how to resolve it, which is defined as Dispute, with reference to article 1, section 6 of the Agreement. Cooperation between You and the Organizer may often prevent this from happening. You can help resolving an issue amongst yourselves, preventing it from becoming a Dispute by:
(a)clearly explaining Your position, providing information, keeping a polite and respectful posture, in compliance with the Information Policy;
(b)providing the Organizer as well as Yourself with a reasonable amount of time to do proper research and to properly and respectfully respond to each other;
(c)verifying that there are no misunderstandings caused by any language barrier;
(d)not engaging in attempts to further escalate the situation by making defamatory statements;
(e)refraining from using the Gaming Service if You are not eligible, compliance with the Exclusion Policy;
(f)refraining from Abuse, compliance with the Anti-Abuse Policy;

10.3. If the issue between You and the Organizer nevertheless becomes a Dispute, You are required to make any reasonable attempt to resolve the Dispute amongst yourselves. You can help to resolve the Dispute by clearly letting the Organizer know:
(a)that there is an issue, explaining when the issue was first raised and with whom;
(b)that You do not agree with how the Organizer are handling the issue, explaining why;
(c)what in Your opinion would be an acceptable solution to the issue;
(d)when in Your opinion the issue should be resolved at the latest.

10.4. You acknowledge, agree and accept that the Organizer are only obligated to: 
(a)attempt to resolve the Dispute if it is properly explained as meant in section 10.2. and if it has been made known to the Organizer as soon as possible, not later than one year after the issue has arised, after which the said obligation shall lapse;
(b)retain the information necessary to investigate the Dispute for a maximum period of two years after You have brought the Dispute to their attention. Upon expiry of the said period, they shall be entitled to discard the said information.

10.5. You acknowledge, agree and accept to raise a Dispute in accordance with the following procedures:
If You do not agree with how Your issue with fukuwin.com has been dealt with, please contact us on the following email address: admin@dginnovations.info. Your email needs to include the following information:
(a) a clear description of the issue;
(b) a statement explaining why You do not agree with how a matter was dealt with.


Complaint Policy: Filing a Complaint for the purposes of resolving a Dispute by Binding Decision
 
EXPLANATION - Articles 11 and 12 of the Gaming Terms and Conditions (GT&C) explain the Complaint Policy. The policy states that if you cannot resolve a problem between you and the organizer, you are required to file a complaint with the intermediary, providing your full name, email address, account details, and a clear explanation of why you disagree with the organizer. The intermediary will then investigate the dispute, listen to both parties, gather evidence, and provide a binding decision on how the dispute will be resolved. If you don't comply with the policy or refuse to provide necessary information, the intermediary may simplify the decision process or dismiss the complaint. The policy also states that you have one month to opt-out of this policy, providing you with the option to try to resolve the dispute in another way.`}
        />
        <PolicySubSection
          title={`Article 11, GT&C`}
          content={`11.1. This article as well as article 12 outlines the Complaint Policy as meant in article 2, section 2, subsection (h) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Complaint  Policy and that You shall comply with the Complaint Policy.

11.2. You acknowledge, agree and accept that if a Dispute between You and the Organizer remains unresolved, You are required under the Complaint Policy to timely lodge a properly formatted notification (the: “Complaint”) with the Intermediary, as soon as possible but in any event within one year after the Dispute was raised with the Organizer.

11.3. You acknowledge, agree and accept that for a notification as meant in section 11.2. to be considered a Complaint, it needs to include:
(a)Your full name, the email address that was used to communicate with the Organizer and the details of the Account; as well as:
(b)any comprehensible description that outlines why You do not agree with the Organizer in this matter.

11.4. You acknowledge, agree and accept that if Your notification does not comply with the said criteria, for example, if it would not clearly mention or explain the existence of a Dispute, the Intermediary is not required to treat the request as a Complaint and the Complaint Policy shall then not apply to Your notification.

11.5. The Parties acknowledge, agree and accept, as set forth in article 1, section 6 of the Agreement:
(a)by entering into the Agreement, to appoint amongst themselves as meant in article 7:900, section 2, CCC, the Intermediary for the purpose of considering all Complaints by Binding Decision, in compliance with the Complaint Policy;
(b)to allow the Intermediary to determine by Binding Decision that the Organizer, and, or You shall be obligated to pay a sum of money, or shall be obligated to perform a performance other than in money. Or it may partially or in full, in the said binding manner, dismiss a Complaint, effectively resolving the Dispute by Binding Decision;
(c)that the Complaint shall include all unresolved Disputes that currently exist between You and the Organizer, regardless of the legal argument and form of action, whether in contract, tort, strict liability or otherwise;
(d)that a Complaint may include a consideration whether an act or conduct has been justifiably deemed as Abuse, in compliance with the Anti-Abuse Policy;
(e)that the Intermediary shall investigate the Dispute by:
(1)hearing Your viewpoints and those of the Organizer; 
(2)establishing the scope of the Dispute and confirming it with You and the Organizer;
(3)allowing You and the Organizer to respond to each others viewpoints and to present evidence;
(4)requesting any additional information it may deem necessary to properly investigate the Dispute. This includes but is not limited to, at a cost, to be borne by the Organizer:
(i) a request for a mandatory verification of the identity by a third party;
	(ii) a request for an audit of the Balance by a financial professional, including but not limited to an accountant. 
(5)presenting its preliminary findings to You and the Organizer, allowing them to comment on it and establishing that You as well as the Organizer have been sufficiently heard;
(6)reconsidering the preliminary findings, taking the comments of Player and the Organizer into consideration;
(7)providing You and the Organizer with its Binding Decision as meant in section 11.5., subsection (a).
(f)that the Intermediary shall prepare its Binding Decision with due observance of the rules and conditions of the Gaming Service as set forth in the Agreement and the GT&C contained herein, the complex of facts, personal circumstances of the Player, applicable legal provisions, industry and other customs, codes of conduct and of what is known to be reasonable and fair given the circumstances of the Complaint;
(g)that the Binding Decision shall in any case contain: 
(1)the names of the Player and the Organizer;
(2)a description of the Complaint and a brief overview of the facts; 
(3)the arguments brought forward and all the evidence; 
(4)the viewpoints of the Player and the Organizer on each others arguments;
(5)the outcome of the investigation by the Intermediary;
(6)the grounds of the Binding Decision; 
(7)the Binding Decision, establishing the legal position between the Player and the Organizer and how the Dispute shall be resolved;
(8)the date of the Binding Decision;
(9)options for the Parties for recourse against the Binding Decision as set forth in the Complaint Policy.
(h)that the Intermediary shall provide You and the Organizer with its Binding Decision within two weeks after having established that You and the Organizer have been sufficiently heard, as meant in section 11.5., subsection e(5). It may extend the said period by another two weeks by notification of the Player and the Organizer. 

11.6. The Parties  acknowledge, agree and accept that, as set forth in article 7:900, section 1, CCC, the Binding Decision shall bind the Parties for the purpose of resolving the Dispute and any uncertainties on the legal situation, intended to also apply and to be binding, even if such a situation would deviate from any pre-existing legal situation. The Binding Decision binds the Parties with effect from the day that the other Parties have been provided by the Intermediary with a copy of the Binding Decision. 

11.7. The Parties acknowledge, agree and accept that the Intermediary may deviate from the procedures as set forth in section 11.5, subsections (d) and (f) and suffice with a simplified, express version of the Binding Decision, only establishing the legal position between You and the Organizer and how the Dispute shall be resolved, if either You or the Organizer during the process of the handling of the Complaint refuse to comply with any of the Policies, including but not limited to:
(1)not timely or not fully complying with a request for additional information, or by being intentionally dishonest or misleading;
(2)other types of Abuse as defined under the Anti-Abuse Policy.

11.8. Before exercising the option as set forth in section 11.7., the Intermediary shall allow the non-compliant Party a period of three Business Days to make a clarification or correction.`}
        />
        <PolicySubSection
          title={`Article 12, GT&C`}
          content={`12.1. This article belongs to the Complaint Policy. It outlines the procedures for recourse against a Binding Decision. You give consent to and shall comply with procedures as set forth in this article.

12.2. The Parties acknowledge, agree and accept that the Player and the Organizer are entitled to a reconsideration of the Binding Decision (the: “Reconsideration”), provided that a properly formatted request is received by the Intermediary within seven Business Days. The said request is deemed properly formatted if it has been filed by the Player or the Organizer with the Intermediary in compliance with the Communications Policy and if it includes any comprehensible description that outlines why the Party requesting the Reconsideration does not agree with the Binding Decision.

12.3. The Parties acknowledge, agree and accept that:
(a)a request for a Reconsideration shall include all evidence, documentation, arguments or viewpoints that the requesting Party intends to provide for purposes of the Reconsideration;
(b)the other Party shall be allowed to respond to the viewpoints of the Party requesting the Reconsideration and it shall be allowed to present evidence in support of a viewpoint; 
(c)the Intermediary shall reconsider the Binding Decision in accordance with the procedures as set forth in section 11.5;
(d)the Intermediary shall prepare an additional Binding Decision as meant in section 11.5., subsection (a), combined with a declaration on behalf of the management of the Intermediary, declaring that it was properly briefed on the Reconsideration. It shall be attached to the Binding Decision that was Reconsidered, equally binding the Parties as set forth in section 11.6.;
(e)For the purpose of a Reconsideration the Intermediary may equally exercise the option as set forth in section 11.7.  

12.4. The Parties acknowledge, agree and accept that, the Player and the Organizer are entitled to request the Gaming Control Board to provide an opinion on whether the Intermediary has properly executed the Complaint Policy. The Parties acknowledge, agree and accept that: 
(a)the Gaming Control Board is under no obligation to provide the said opinion;
(b)the said opinion does not affect Binding Decisions in any way.

12.5. The Parties acknowledge, agree and accept that under the penalty of inadmissibility in arbitration or court, a request for the annulment of a Binding Decision, as set forth in article 7:904, CCC, can only be filed with a competent panel or arbitration or court:
(a) within a period of one calendar month after that Party was provided with the Binding Decision; and
(b) after the Intermediary was requested to reconsider the Binding Decision in accordance with section 12.2.; and
(c) after the Gaming Control Board was requested to provide its opinion, in accordance with section 12.4.

12.6 The Parties acknowledge, agree and accept that if the Parties are deemed legally bound to the Agreement and the GT&C herein, they shall refrain from making a request for an annulment as set forth in section 12.5. on the basis of arguments that would imply that the Parties are not legally bound, such as, but not limited to:
(a)“I do not want to be bound to the outcome of the Binding Decision as it is not in my favor”;
(b)“Although I did not opt out of the Compliance Policy, I do not trust the Intermediary in its appointed role”;
(c)“I did not have a proper understanding of the Agreement and the GT&C contained herein”;
(d)“I have not been provided with a reasonable opportunity to review the Agreement and the GT&C contained herein”;
(e)“I was forced or tricked to participate in obtaining a Binding Decision”; 
(f)“I was not, or not sufficiently aware or made aware that I could have opted out of the Complaint Policy”;
(g)“I am not bound to the Agreement and the GT&C as a right of claim was transferred to me by someone else”;
(h)“Certain information that I have not shared with the Intermediary would put matters into a different perspective”;

12.7. The Parties acknowledge, agree and accept that:
(a)the Binding Decision shall be under all given circumstances considered acceptable by standards of reasonableness and fairness, as set forth in article 7:904, section 1, CCC, if You file a request for an annulment as set forth in section 12.5., without having complied with the requirements as set forth in that section and section 12.6., as You would then have ignored reasonably provided and less invasive options for recourse against the Binding Decision;
(b)the Intermediary shall in its Binding Decision only make a determination of the relationship between the Player and the Organizer pertaining to their performance within the confines of the Gaming Service, as set forth in article 1, subsection 6 of the Agreement;
(c)any restrictions or limitations imposed under any of the Policies are deemed reasonable and fair, as meant in article 7:904, section 1, CCC as these restrictions are derived from the the Gaming License and have been imposed for Your own protection as set forth in section 4.3., subsection (b);
(d)in the event that any clause or provision of the Binding Decision is declared partially annulled, void, illegal, or unenforceable by an arbiter or court of competent jurisdiction, its remaining provisions shall remain in full force and effect. You acknowledge, agree and accept to negotiate in good faith to replace any invalid provision with a valid provision that achieves the same or similar objectives, as set forth in article 7:904, section 2, CCC;

12.8. You may, for any reason, opt-out of the Complaint Policy, seeking other means to resolve a Dispute, for example on the basis that a Binding Decision would under the given circumstances be unacceptable by standards of reasonableness and fairness. You may personally declare to-opt out within one calendar month after any of the Service Parties has first notified You that it intends to follow the Complaint Policy, by sending an email to Curacao eGaming, declaring that You wish to opt-out of the Complaint Policy. Once opted out, You remain opted out of the said Policy for that specific Dispute between You and the Organizer.

12.9. You acknowledge, agree and accept that:
(a)You are provided with another opportunity to opt-out of the Complaint Policy, within one calendar month, each and every time after You have received notification by one of the Service Parties that it intends to follow the Complaint Policy;
(b)if You have not opted-out of the Complaint Policy, You shall be legally bound to and to comply with the said policy and that You shall abide by a Binding Decision and that the Binding Decision given the multiple opportunities offered to opt-out is in the given circumstances considered acceptable by standards of reasonableness and fairness, as set forth in article 7:904, CCC.


Liability Policy

EXPLANATION - Article 13 of the Gaming Terms and Conditions (GT&C) explains the Liability Policy. It says that you have to follow and accept the limitations of liability that come with using the services. If there are any problems or disputes, you need to bring them up within a specific time limit or else they won't be considered. The rules and restrictions mentioned in the text are there to protect both the service providers and the consumer, and they encourage responsible use of the services.`}
        />
        <PolicySubSection
          title={`Article 13, GT&C`}
          content={`13.1. This article outlines the Liability Policy as meant in article 2, section 2, subsection (i) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Liability Policy and that You shall comply with the Liability Policy.

13.2. You acknowledge, agree and accept that the following limitations of liability apply to the Services offered under the Agreement and its GT&C contained herein, regardless of the form of action, whether in contract, tort, strict liability or otherwise: 
(a)by making a Deposit, You assume a certain level of risk associated with the use of the Services. The maximum liability of Service Parties to the Player for any damages, losses, or claims arising from or related to the use of the Services shall therefore be limited to the total amount of Deposits;
(b)under penalty of inadmissibility in arbitration or court, any claim arising out of or related to the Agreement and its GT&C contained herein, including the breach, termination, or validity thereof, shall be brought no later than two years after the date on which the potential grounds for a claim arose. Any claim not brought within this two-year period shall be deemed waived and forever barred;
(c)under penalty of inadmissibility in arbitration or court, none of the Service Parties with exception of the Operator may be held liable for any matters related to Withdrawals, as defined in the Withdrawals Policy. This includes, but is not limited to any arguments, disagreements, controversies or dissensions pertaining to the transferability of Credits as set forth the Account Policy;
(d)the Intermediary cannot not be held liable for any acts or omissions regarding Complaint Policy, including the contents of Binding Decisions;
(e)if a third party is deemed a subordinate to any of the Services Parties, such subordinate may invoke any legal defense made by the Service Parties, as if that subordinate were a party to the Agreement and the GT&C contained herein, as set forth in article 6:257, CCC.

13.3. You acknowledge, agree and accept that the Agreement and the GT&C contained herein is only effective between the Parties. Notwithstanding any limitations that may apply, there shall be no obligation to pay compensation if the violated standard does not serve to protect against the damage suffered by the injured party as set forth in article 6:163, CCC. 

13.4. You acknowledge, agree and accept that the restrictions and limitations set forth in this article:
(a) represent a reasonable allocation of risk given the commercial nature of the Services, as:
(1) they reflect the value of the consideration provided by the Player to the Service Parties;
(2) the allocation of risk is reasonably divided and in alignment with the responsibilities and tasks of each Service Party under the Agreement and the GT&C contained herein.
(b) incentivize responsible gaming behavior and discourages excessive gambling, as Players are more likely to exercise caution when they know that their potential losses are limited to the amount of Deposits made. 

13.5. This article shall survive the termination or expiration of the Agreement and its GT&C contained herein.


Communications Policy

EXPLANATION - Article 14 of the Gaming Terms and Conditions (GT&C) explains the Communications Policy. It emphasizes the importance of keeping your personal information up-to-date and being cautious when communicating with others. It also mentions that only specific methods of communication should be used with the organizer and the intermediary. It states that no one can change these rules without permission, and unauthorized changes may lead to legal consequences.`}
        />
        <PolicySubSection
          title={`Article 14, GT&C`}
          content={`14.1. This article outlines the Communications Policy as meant in article 2, section 2, subsection (j) of the Agreement. You acknowledge, agree and accept that You are legally bound to the Communications Policy and that You shall comply with the Communications Policy.

14.2. You acknowledge, agree and accept that any information or notices sent via email pertaining to the Agreement and the GT&C contained herein, will be considered valid and legally binding. 


14.3. You acknowledge, agree and accept that: 
(a)under the Information Policy, You have an obligation to keep Your personal information up-to-date, this includes, but is not limited to making sure that Your email address remains up-to-date;
(b)You have an obligation to ensure that You are communicating with a legitimate Party;
(c)there are various ways to verify whether You are dealing with a legitimate Party, with reference to article 4, sections 3 and 4 of the Agreement;
(d)You should not provide any sort of information if You are not sure that You have been presented with verifiable information;
(e)You should never respond to emails that seem suspicious, such as emails that do not use a personalized domain, or a general email service such as “@gmail.com” or “@hotmail.com”; 
(f)if You do find anything suspicious, report it to any of the Service Parties immediately;
(g)any titles, subtitles or texts in the Agreement and GT&C that are marked with: “EXPLANATION” are only for clarification purposes. You cannot use them to make any legal claims or derive any legal rights from it. The actual legal articles are the only valid source of information for legal purposes.

14.4. You acknowledge, agree and accept that obvious mistakes, including typographical errors or clerical errors, contained within the Agreement shall not be binding upon the Parties. Such mistakes, when clearly identifiable as unintended errors, shall be subject to correction or clarification without invalidating the remaining provisions of the Agreement and the GT&C contained herein. Notwithstanding the above, any proposed correction or clarification to obvious mistakes shall be subject to the mutual agreement of the Parties. 

14.5. You acknowledge, agree and accept to only use the following methods of communication with the Organizer: 
For all matters relating to fukuwin.com please contact us on the following email address:
admin@dginnovations.info.

14.6. You acknowledge, agree and accept to only use the following methods of communication with the Intermediary:
(a)to file Your Complaint: visit curacao-egaming.com and use the Complaint portal to file Your Complaint;
(b)to request for a Reconsideration of a Binding Decision or review by the Gaming Control Board: send Curaçao eGaming an email to the following address: player-support@curacao-egaming.com;
(c)	to opt-out as set forth in article 7.3. of the Agreement: send Curaçao eGaming an email 
(d)	to opt-out as set forth in section 12.8. of the Complaint Policy: send Curaçao eGaming
(e)	for matters visit curacao-egaming.com and select the provided method of communication to contact Curaçao eGaming.

14.7. No person or entity shall have the right to modify or amend the Agreement and the GT&C contained herein without the prior written consent of the Services Parties. Any unauthorized distribution or modification may result in legal action and the enforcement of all available remedies under copyright law.`}
        />
      </div>
    </div>
  );
};

export default RgaNGtcContent;
