import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './PolicyAgreement.scss';
import {useTranslation} from 'react-i18next';
import BaseCheckBox from './BaseCheckBox';
import {useNav} from '../../utils/helper';

const PolicyAgreement = forwardRef((allProps, ref) => {
  const navigate = useNav();
  const {onChange, value, name} = allProps;
  const {t: trans} = useTranslation();
  const containerRef = useRef(null);
  const [errMsg, setErrMsg] = useState('');

  const handleErrMsg = (errMsg) => {
    setErrMsg(errMsg);
  };

  return (
    <div ref={containerRef} className="policyAgreement-container">
      <div className="policyAgreement-input-container">
        <BaseCheckBox
          {...allProps}
          containerRef={containerRef}
          ref={ref}
          value={value}
          onChange={onChange}
          errMsgCallBack={handleErrMsg}
        />
        <div
          className="policyAgreement-input-text"
          onClick={() => {
            if (onChange) {
              onChange({name: name, value: !value});
            }
          }}>
          {trans('register.agreement_part1')}
          <span
            className="policyAgreement-special-key"
            onClick={(e) => {
              e.stopPropagation();
              navigate('/amlPolicy');
            }}>
            {trans('register.privacyPolicy')}
          </span>
          {trans('register.agreement_part2')}
          <span
            className="policyAgreement-special-key"
            onClick={(e) => {
              e.stopPropagation();
              navigate('/rgaNGtc');
            }}>
            {trans('register.rgaNgtc')}
          </span>
        </div>
      </div>
      {errMsg ? (
        <div className="policyAgreement-error-msg">{trans(errMsg)}</div>
      ) : null}
    </div>
  );
});

export default PolicyAgreement;
