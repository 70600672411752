import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  enableGoogleAuth: false,
  isLoading: true,
};

export const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    setEnableGoogleAuth: (state, action) => {
      state.enableGoogleAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSecurityPageLoad.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchSecurityPageLoad.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      if (action.payload) {
        state.enableGoogleAuth = action.payload.enableGoogleAuth;
      }
    });
    builder.addCase(fetchSecurityPageLoad.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const fetchSecurityPageLoad = createAsyncThunk(
  'security/fetchSecurityPageLoad',
  async (_, {dispatch, getState}) => {
    return new Promise((resolve, reject) => {
      api.SecurityPageLoad({
        success: (res) => {
          resolve(res.data);
        },
        error: (err) => {
          reject(err);
        },
      });
    });
  },
);

export const {setEnableGoogleAuth} = securitySlice.actions;
export default securitySlice.reducer;
