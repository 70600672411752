import React, {useRef, useLayoutEffect} from 'react';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useSpring, animated} from 'react-spring';
import './Footer.scss';
import {GetHome} from '../../resources/image/Image';
import More from '../more/More';
import api from '../../common/api';
import {useNav as useNavigate, IsSpecialUser} from '../../common/utils/helper';
import FooterItem from './FooterItem';
import scssVariables from '../../resources/theme/Common.scss';

export default function Footer() {
  const navigate = useNavigate();
  const {CMFooter} = useSelector((state) => state.styling);
  const {t: trans} = useTranslation();
  const promo = false; //invite angpao
  const Home = GetHome();
  const footerRef = useRef();

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      const newHeight = footerRef.current.getBoundingClientRect().height;
      const root = document.documentElement;
      if (
        window.innerWidth <=
        scssVariables.SideMenuResponsiveWidth.replace('px', '')
      ) {
        root.style.setProperty('--footer-height', `${newHeight}px`);
      } else {
        root.style.setProperty('--footer-height', `0px`);
      }
    });

    observer.observe(footerRef.current);

    return () => {
      observer.disconnect();
    };
  }, [footerRef]);

  function Render() {
    return CMFooter.map((item, index) => {
      var icon;
      var name;
      var to;
      var path = '-';

      switch (item) {
        case 'reward':
          icon = Home.footer.reward;
          name = trans('homepage.act.reward');
          to = () => {
            if (promo) {
              api.UserInviteAngpao({});
            } else {
              navigate('/mission');
            }
          };
          path = '/mission';
          break;

        case 'mission':
          icon = Home.footer.mission;
          name = trans('homepage.act.mission');
          to = () => {
            if (promo) {
              api.UserInviteAngpao({});
            } else {
              navigate('/mission');
            }
          };
          path = '/mission';
          break;

        case 'fund':
          icon = Home.footer.fund;
          name = trans('homepage.act.fund');
          to = () => {
            navigate('/fund', {
              state: {title: 'Fund'},
            });
          };
          path = '/fund';
          break;

        case 'affiliate':
          icon = Home.footer.affiliate;
          name = trans('homepage.act.affiliate');
          to = () => {
            navigate('/affiliate');
          };
          path = '/affiliate';
          break;

        case 'chat':
          icon = Home.footer.chat;
          name = trans('homepage.act.chat');
          to = () => {
            navigate('/chat', {
              state: {title: 'Chat'},
            });
          };
          path = '/chat';
          break;

        case 'club':
          icon = Home.footer.club;
          name = trans('homepage.act.club');
          to = () => {
            navigate('/club');
          };
          path = '/club';
          break;
        case 'vip':
          icon = Home.footer.club;
          name = trans('homepage.act.vip');
          to = () => {
            navigate('/club');
          };
          path = '/club';
          break;

        case 'more':
          icon = Home.footer.more;
          name = trans('homepage.act.more');
          to = () => {
            document.getElementById('more_slider').style.transform =
              'translateX(0%)';
            document.getElementById('more_slider').style.display = 'block';
            document.body.style.overflow = 'hidden';
            document.body.style.touchAction = 'none';
          };
          break;

        case 'home':
          icon = Home.footer.home;
          name = trans('homepage.act.home');
          to = () => {
            navigate('/');
          };
          path = '/';
          break;

        case 'game':
          icon = Home.footer.game;
          name = trans('homepage.act.game');
          to = () => {
            navigate('/game');
          };
          path = '/game';
          break;
        default:
          break;
      }

      return (
        <FooterItem
          key={item}
          icon={icon}
          to={to}
          name={name}
          promo={promo}
          path={path}></FooterItem>
      );
    });
  }

  return (
    <div
      className="footer-container"
      ref={footerRef}
      style={IsSpecialUser() ? {padding: 0, height: 0} : null}>
      {/* <More /> */}
      {IsSpecialUser() ? null : <Render />}
    </div>
  );
}
