import React from 'react';
import './AmlPolicyContent.scss';
import PolicyTitle from '../../common/components/text/PolicyTitle';
import PolicySubSection from '../../common/components/text/PolicySubSection';

const AmlPolicyContent = () => {
  return (
    <div className="amlPolicyContent-container">
      <PolicyTitle text={'Anti Money Laundering Policy'} />
      <div className="amlPolicy-content">
        <PolicySubSection
          title={'1.Introduction'}
          content={`1.1Money Laundering

      Money Laundering (ML) is the process of creating the appearance of funds obtained from criminal activity, such as drug trafficking or terrorist activity, being originated from a legitimate source. Criminals attempt to launder money by disguising the source and/or changing the form of the funds, or moving them to a place where they are less likely to attract attention.
      
      1.2Anti-Money Laundering

      Anti-money laundering (AML) is a financial/legal term used to describe the legal controls that require financial institutions and other regulated entities to prevent, detect, and report ML activities. 
      An effective AML program requires a jurisdiction:
      
      •	To have criminalized Money Laundering
      •	To have given the relevant regulators and police the powers and tools to investigate it
      •	To have its financial institutions identify their customers, establish risk-based controls, keep records and report suspicious activities
      •	To be able to share information with other jurisdictions as appropriate`}
        />
        <PolicySubSection
          title={'2.Regulation'}
          content={`Employees working in the remote gaming industry are required to make a report in respect of information that comes to them in the course of their business:

      •	When they know
      •	When they suspect
      •	When they have reasonable grounds for knowing or suspecting that a person is engaged in money laundering or terrorist financing, including criminal spend.
      
      These obligations are collectively referred to as grounds for knowledge or suspicion. What the authorities are looking for is that we are able to demonstrate, with supporting evidence, that a risk assessment is and has been undertaken prior to entering into business relationships with customers and that adequate customer due diligence is conducted in order to ensure that customers’ transactions are consistent with the level of risk presented.
      
      The Merchant must be able to demonstrate that the extent of the ongoing monitoring undertaken is conducted on a risk-sensitive basis and that all the records are retained by us to reflect this, with risk profiles being properly maintained. In this document, we have identified additional measures that are being applied in order to carry out risk monitoring and the need of where we would require a Declaration of the source of funds from customers in situations which present a high risk and potentially money laundering.
      
      2.1Specific Regulations
      
      The Merchant is committed to ensuring that compliance is embedded in all aspects of our business and licensed Сuracao Gaming License that allow the operations of Games of Chance on the Internetional market via service lines.`}
        />
        <PolicySubSection
          title={'3.Crime & Disorder and AML policy'}
          content={`AML policy is based on principles and practises:

      •	We develop systems and controls that are appropriate for our businesses and comply with legal and regulatory requirements
      •	We assess the AML risks inherent in our current business at least annually; we then adopt a risk-based approach that is flexible, effective, proportionate and cost effective
      •	We have full commitment for this from, and responsibility resting with, senior management
      •	We regularly assess the adequacy of our systems and controls
      •	We maintain, where necessary, records of transactions that meet the needs of law enforcement investigations tackling money laundering and terrorist financing
      •	We provide initial and ongoing training for all relevant employees 
      •	We support the nominated officer with resources and authority to operate objectively and independently`}
        />
        <PolicySubSection
          title={'4.Risk Management'}
          content={`We have a policy and procedures in relation to risk assessment and management, as required under the Money Laundering Regulations 2007 (the Regulations). This risk-based approach involves a number of discrete steps in assessing the most proportionate way to manage and mitigate the money laundering and terrorist financing risks we face:

•	Identify the money laundering and terrorist financing risks that are relevant to us 
•	Design and implement policies and procedures to manage and mitigate these assessed risks 
•	Monitor and improve the effective operation of these controls
•	Record what has been done, and why

This risk-based approach focuses the effort where it is most needed and will have most impact. It has the full commitment and support of our senior management, and the active co-operation of all employees.We have conducted an assessment of our business risk exposure to money laundering, which considers the threat, and its impact.`}
        />
        <PolicySubSection
          title={`5.Suspicious Activity`}
          content={`Suspicious Activity in this case is being referred to as suspicious transactions, extreme player profiles, when deposits are not matching up amongst other elements. Other concrete examples of how we identify players who require our team to undertake a risk monitoring approach of our customers and when to specifically carry out enhanced due diligence checks on the Player Profiles can be further required:

1.	Passport or ID card.
2.	Utility bill.
3.	Bank statement.
4.	Other proof of identity.

The Enhanced Due Diligence Checks are subject to players’ profile and the amount of Risk they pose to us. Only when we determine some of the above points or a combination of a few will we flag the customer/customers in question and conduct risk monitoring. This will include checks of where the customer works, value of house, where the customer lives and checks to see whether the value of the house is logical compared to the customers spending. 

5.1Suspicious Activity Reports SARs

Within that framework, Suspicious Activity Reports (SARs) are an imposed requirement. The Merchant ensures that any employee reports to the Risk Team where they have grounds for knowledge or suspicion that a person or customer is engaged in ML or terrorist financing. Any employee failing to so do is open to criminal prosecution.

Escalations of SARs should be done in a confidential, discreet manner, in a handwritten form and not via email so as to ensure maximum anonymity.

An employee must not, under any circumstances, disclose or discuss any AML concern with the person or persons subject to an investigation, or any other person for that matter. Disclosure (also known as "tipping off") is strictly prohibited and carries with it very serious legal penalties. 

Furthermore, and in order to keep ourselves protected as much as possible, no remark should ever be left on an account that would give any indication that ML is suspected, a player being entitled, at any point in time, to request the full notes/remarks on their account.


5.2Working Procedure

Merchant reviews players spend and game play to check for suspicious activity. Before any withdrawal is processed the following procedures are carried out:

1.	The customers deposit history is reviewed to confirm that no suspicious payments have been made to the customer’s account. The frequency of deposits and the sum of deposits are reviewed to ensure they are within normal range for the customer based on his depositing history and the general depositing range throughout our network. 
2.	The customer’s turnover is reviewed to ensure that they have played in the casino and are not using Merchant as a method to move money. 
3.	When possible, funds must always be refunded back to the original payment method used by the player to make a deposit. 

5.3Withdrawal Procedure

When reviewing a customer’s account prior to withdrawal the agent must answer the following questions in the AML segment in the Risk Entry:

1.	Did the player wager?
2.	Does the payment Method belong to the player and has the player used it to deposit?
3.	Are the customer’s transactions and bets in line with expectations for the player?

5.4Escalation Process

Following the AML policies in place and escalating any suspicious activity, as previously described, are crucial to the company as they protect it from financial losses and ensure that it remains compliant within the different jurisdictions which govern it.

Any activity which appears suspicious, even only slightly, has to be escalated. Not escalating a suspicion of money laundering can lead to criminal prosecution.`}
        />
        <PolicySubSection
          title={`6.Employees`}
          content={`6.1Senior Management

Senior management is fully committed to and responsible for the implementation of this policy. Senior management is made aware of their individual personal liability for consenting to, or conniving in, the commission of offences under the Regulations, or where such offence is attributable to any neglect on his part.


6.2MLRO

A designated Money Laundering Reporting Officer takes responsibility for SARs in respect of the prevention and detection of money laundering, counter terrorism financing and our obligations under the Proceeds of Crime Act 2000 (and separately under parallel regime for SARs arising from systems located in Malta 
The nominated MLRO has responsibility for:

•	making reports to senior management on anti-money laundering (AML) and countering terrorist financing (CTF) activity; 
•	receiving disclosures from employees under Part 7 of the Proceeds of Crime Act 2002 (POCA) and Part III of the Terrorism Act 2000 (the Terrorism Act); 
•	and if appropriate, making such external reports. 

The MLRO has the authority to act independently in carrying out their responsibilities, and has access to sufficient resources to carry out their duties.

6.3Staff training

All staff will receive training on their obligations in respect of money laundering reporting and are aware of the procedures in place for escalation of any suspected incidents to the MLRO. As part of this process, staff are made aware that personal disregard for the legal requirements, for example, turning a blind eye to a customer spending criminal proceeds, may result in criminal or regulatory action.

Relevant employees receive training on how to follow house policies and procedures for: 

•	Client due diligence (CDD), including enhanced requirements for high risk clients, which includes PEPs; 
•	reporting suspicious activity to the nominated officer where necessary, seeking appropriate consent to allow participation in gaming and to conduct gaming and other business transactions`}
        />
        <PolicySubSection
          title={`7.High Risk Jurisdictions`}
          content={`High risk countries are those countries identified as such by publications issued from time to time by the Financial Action Task Force; or additionally those so identified by the Gambling Commission. Client registered in High Risk Countries are always subject to EDD.






Countries currently on the FATF list are:

•	Afghanistan
•	Algeria
•	Angola
•	Bosnia & Herzegovina
•	Ecuador
•	Guyana
•	Iraq
•	Lao PDR
•	Myanmar
•	Panama
•	Papua New Guinea
•	Syria
•	Uganda
•	Yemen
•	Iran
•	Democratic People’s Republic of North Korea

Players from the FATF list of jurisdictions seen to threaten the international financial system from on-going and substantial money-laundering or terrorist financing activities, as identified on FATF publications, will be refused.`}
        />
        <PolicySubSection
          title={`8.Record keeping`}
          content={`We ensure that there is an audit trail to assist in any financial investigation by a law enforcement body. Our record keeping policy and procedure covers records in the following areas: 

•	Details of how compliance has been monitored by the nominated officer
•	Delegation of AML/CTF tasks by the nominated officer
•	Nominated officer reports to senior management 
•	Information not acted upon by the nominated officer, with reasoning why no further action was taken
•	Customer identification and verification information
•	Supporting records in respect of business relationships or occasional transactions
•	Employee training records 
•	Internal and external SARs 
•	Contact between the nominated officer and law enforcement or the NCA, including records connected to appropriate consent`}
        />
        <PolicySubSection
          title={`9.Offences`}
          content={`All employees are made aware of their risk of committing the following related offences:

•	POCA and the Terrorism Act create offences of failing to report suspicious activity 
•	Where an employee fails to comply with the obligations to make disclosures to a nominated officer, or the nominated officer to NCA*, as soon as practicable after the information giving rise to the knowledge or suspicion comes to that person, they are open to criminal prosecution
•	They may also commit an offence under POCA or the Terrorism Act if they disclose information that an SAR has been submitted that is likely to prejudice any investigation, or disclose information that an investigation into allegations that an offence under POCA or the Terrorism Act has been committed, that is likely to prejudice the investigation
•	They also commit an offence if they know or suspect that an appropriate officer is acting (or proposing to act) in connection with a relevant investigation which is being or is about to be conducted, and they falsify, conceal, destroy or dispose of documents which are relevant to the investigation`}
        />
        <PolicySubSection
          title={`10.Vetting procedures for new employees`}
          content={`The company undertakes a number of vetting procedures when staff are employed. We will ensure the employee is not a minor through proper identification checks and we will verify the identification and credentials of the employee through at least two independent references. We will also look to verify any further personal information or background information.`}
        />
        <PolicySubSection
          title={`11.Protecting our equipment from internal crime and criminal misuse `}
          content={`The company is very aware that a key way to combat fraud is to first identify where the company's most valuable assets are. Processes and controls have been built into the routine business of the company to minimize the chances of any of the key assets being misused. 

Our server equipment are located at Unicept Malta who have in place necessary policies around protection of equipment, for example, a visitors management procedure, fire alarms, shredding of confidential documents, locked cabinets, testing, a security team etc. Merchant also implements such policies at our offices. `}
        />
        <PolicySubSection
          title={`12.Compliance and Risk Committee`}
          content={`We intend to establish this to review executive risk. Both the MLRO and Compliance Officer will be members as will another non-executive director. These will meet no less than quarterly or as required.`}
        />
        <PolicySubSection
          title={`13.Ensuring the companies we deal with are trustworthy and reputable`}
          content={`Merchant promotes strong principles of business and professional ethics at every level. When selecting suppliers, there are a number of criteria we consider:

•	Financial strength (for long term sustainability);
•	Legal and regulatory compliance;
•	Commitment to a wider corporate responsibility program;
•	Desire and ability to deliver quality and value. 

All new suppliers must go through a rigorous approvals process where all information put forward by them is verified. 

This information is then assessed internally to consider the risks associated with the supplier; taking into account all of the above criteria. If rejected, the supplier will be informed. Merchant ensures that all organisations we contract with understand the compliance obligations under the relevant player jurisdictions. `}
        />
        <PolicySubSection
          title={`14.Our responsibilities under the Proceeds of Crime Act (POCA)`}
          content={`Merchant is fully aware of the procedures and policies required by the Proceeds of Crime Act 2002 and have policies and procedures in place related to this Act (as detailed below).`}
        />
        <PolicySubSection
          title={`15.Internal record keeping`}
          content={`The below key principles: know your customer, internal record keeping and reporting, ensure our compliance with laws and regulations.

•	Records on all customer transactions - regardless of their relation to payments or plays - will be kept for at least 6 years after the transaction. 
•	Records on customer details - regardless of their value state or open/blocked status - will be kept for at least 6 years after the relationship with the customer was terminated. 
•	Records on money laundering investigations and suspicious activity reports will be kept for 6 years after the investigation was completed. `}
        />
        <PolicySubSection
          title={`16.Prevention of collusion and data protection compliance`}
          content={`The Merchant Terms and Conditions make clear that cheating will not be tolerated and that customer accounts will be closed if cheating occurs.

In terms of data protection, all data related to user payment accounts is stored, encrypted, and managed by Payment IQ, and Merchant employees have no access to user payment information. All Customer Information in the organization will be protected through a strict Information Security policy that all employees and suppliers will adhere to, that will include System Access and Authentication Control, Password Policy, Malware Protection measures, Intrusion Prevention policy, Encryption Policy, and strict Network Control and Management.`}
        />
      </div>
    </div>
  );
};

export default AmlPolicyContent;
