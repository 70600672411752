import React from 'react';
import './RgaNGtc.scss';
import PolicyTitle from '../../common/components/text/PolicyTitle';
import PolicySubSection from '../../common/components/text/PolicySubSection';
import BackNav from '../../common/components/navigationBar/BackNav';
import {GetAttachmentImg} from '../../resources/image/Image';
import {clientInfo} from '../../resources';
import RgaNGtcContent from '../../resources/components/rgaNGtcContent';

const RgaNGtc = () => {
  const attachmentImg = GetAttachmentImg();
  return (
    <div className="rgaNGtc-container container-padding">
      <div className="rgaNGtc-style">
        <div className="rgaNGtc-sticky-header">
          <BackNav
            rightComponent={
              <div className="rgaNGtc-nav-action">
                <img
                  className="rgaNGtc-nav-action-img"
                  onClick={() => {
                    window.print();
                  }}
                  src={attachmentImg.print}
                />
                <a
                  href={'/attachments/RGA and GT&C.pdf'}
                  download="RGA and GT&C"
                  target="_blank"
                  rel="noopener noreferrer">
                  <img
                    className="rgaNGtc-nav-action-img"
                    src={attachmentImg.download}
                  />
                </a>
              </div>
            }
          />
        </div>
        <RgaNGtcContent />
      </div>
    </div>
  );
};

export default RgaNGtc;
