import React, {forwardRef, useEffect, useRef, useState} from 'react';
import './LoginDatePickerTextField.scss';
import BaseTextField from './BaseTextField';
import {useTranslation} from 'react-i18next';
import {GetTextField} from '../../../resources/image/Image';
import DatePickerTextField from './DatePickerTextField';

const LoginDatePickerTextField = forwardRef(
  ({icon, showErrImg = false, ...props}, ref) => {
    const tfImg = GetTextField();
    const {t: trans} = useTranslation();
    const containerRef = useRef(null);
    const [errMsg, setErrMsg] = useState('');
    const [isFocused, setIsFocused] = useState('');

    const handleErrMsg = (errMsg) => {
      setErrMsg(errMsg);
    };

    return (
      <div
        ref={containerRef}
        className="loginDatePickerTf-container"
        style={props.disabled ? {opacity: '0.7'} : {}}>
        <div
          className={`loginDatePickerTf-input-container ${
            errMsg ? 'loginDatePickerTf-error-input-container' : ''
          } ${isFocused ? 'loginDatePickerTf-focus-input-container' : ''}`}>
          <div className="loginDatePickerTf-icon-container">
            <img
              className="loginDatePickerTf-icon-img"
              alt={props.name ? props.name : 'icon'}
              src={icon}
            />
          </div>
          <div style={{flex: '1'}}>
            <DatePickerTextField
              {...props}
              inputClassName={'loginDatePickerTf-container'}
              containerRef={containerRef}
              ref={ref}
              errMsgCallBack={handleErrMsg}
              isFocusedCallBack={setIsFocused}
            />
          </div>
        </div>
        {errMsg ? (
          <div className="loginDatePickerTf-error-msg">{trans(errMsg)}</div>
        ) : null}
      </div>
    );
  },
);

export default LoginDatePickerTextField;
