import React, {useEffect, useRef, useState} from 'react';
import './EnterPassword.scss';
import {useTranslation} from 'react-i18next';
import FormComponent from '../../common/components/Form/FormComponent';
import PasswordTextField from '../../common/components/TextField/PasswordTextField';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import {clientInfo} from '../../resources';
import api from '../../common/api';
import {useLocation} from 'react-router-dom';
import {useNav, useVerifyPasswordAndAuth} from '../../common/utils/helper';
import {ContinueJourneyNavigate} from '../../common/utils/loginHelper';

export default function EnterPassword() {
  const location = useLocation();
  const navigate = useNav();
  const {mobile} = location.state || {};
  const pwTfName = 'password';
  const {t: trans} = useTranslation();
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const [formData, setFormData] = useState({});
  const registerConfig = clientInfo.register;
  const verifyPasswordAndAuth = useVerifyPasswordAndAuth();

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue({});
    console.log(formData);
    if (isValid) {
      const password = formData[pwTfName];
      VerifyPassword(mobile, password);
    }
  };

  const VerifyPassword = (mobile, password) => {
    verifyPasswordAndAuth({
      userId: mobile,
      password: password,
      success: (res) => {
        ContinueJourneyNavigate(navigate);
      },
      error: (res) => {
        if (res.errcode === '101') {
          localStorage.removeItem('verified');
        }
      },
    });
  };

  return (
    <div className="enterPassword-container container-padding">
      <div ref={containerRef} className="enterPassword-style">
        <div className="enterPassword-title-section">
          <div className="enterPassword-title">
            {trans('enterPassword.title')}
          </div>
        </div>
        <FormComponent
          ref={formRef}
          frameRef={containerRef}
          className="enterPassword-form-container">
          {registerConfig.password && (
            <PasswordTextField
              isFormChild={true}
              name={pwTfName}
              placeholder={trans('general.placeholder.password')}
              value={formData[pwTfName] || ''}
              isRequired={true}
              enableHideShow={false}
              formatValidation={false}
              onChange={(e) => {
                handleFieldChange(e);
              }}
            />
          )}
        </FormComponent>
        <div className="enterPassword-btn-section-container">
          <ButtonComponent_v2
            text={trans('signIn.login')}
            btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
            btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
            onClick={() => {
              handleButtonClick();
            }}
          />
        </div>
      </div>
    </div>
  );
}
