import {Route} from 'react-router-dom';
import {t as trans} from 'i18next';
import {lazy} from 'react';
import GamesLog from '../gamesLog/GamesLog';
import ChangePassword from '../sign_in_out/ChangePassword';
import LoginProhibitionConfig from '../profile/loginProhibitionConfig';

// More
const Profile = lazy(() => import('../more/Profile'));

// Fund
const Fund = lazy(() => import('../wallet/Fund'));
const Withdraw = lazy(() => import('../wallet/Withdraw'));
const TopupDetail = lazy(() => import('../wallet/TopupDetail'));
const Topup = lazy(() => import('../wallet/Topup'));

// Comm
const Comm = lazy(() => import('../wallet/Comm'));
const Comm2Wallet = lazy(() => import('../wallet/Comm2Wallet'));
const Comm2Bank = lazy(() => import('../wallet/Comm2Bank'));

// Bank
const BankList = lazy(() => import('../bank/BankList'));
const AddBank = lazy(() => import('../bank/AddBank'));

// Web
const LearnMore = lazy(() => import('../web/LearnMore'));
const Website = lazy(() => import('../web/Website'));
const Avatar = lazy(() => import('../more/Avatar'));
const Premium = lazy(() => import('../web/Premium'));
const Streaming = lazy(() => import('../home/Streaming'));
const Porn = lazy(() => import('../home/Porn'));
const Movie = lazy(() => import('../home/Movie'));

// Transaction
const CommRecord = lazy(() => import('../listing/CommRecord'));
const Transaction = lazy(() => import('../listing/Transaction'));
const TransactionDetail = lazy(() => import('../listing/TransactionDetail'));
const GameRecord = lazy(() => import('../listing/GameRecord'));

// Downline
const Affiliate = lazy(() => import('../affiliate/Affiliate'));
const Downline = lazy(() => import('../downline/Downline'));
const DownlineDetail = lazy(() => import('../downline/DownlineDetail'));

// Mission
// const Mission = lazy(() => import('../mission/Mission'));

// User
const CustomerService = lazy(() => import('../cs/CustomerService'));
const ChangePin = lazy(() => import('../more/ChangePin'));
const Chat = lazy(() => import('../chat/Chat'));
const ReconnectRef = lazy(() => import('../downline/ReconnectRef'));
const Deposit = lazy(() => import('../wallet/Deposit'));
const AutoDeposit = lazy(() => import('../wallet/AutoDeposit'));

// Club
const Club = lazy(() => import('../club/Club'));

//Statement
const Statement = lazy(() => import('../statement/Statement'));

//Security
const SecurityMain = lazy(() => import('../profile/Security/SecurityMain'));

//setup google auth
const SetupGoogleAuth = lazy(() =>
  import('../profile/Security/SetupGoogleAuth'),
);

//unbind google auth
const UnbindGoogleTwoFAAuth = lazy(() =>
  import('../profile/Security/UnbindGoogleTwoFAAuth'),
);

export function GetRoute() {
  const routes = [
    {
      path: '/reconnectRef',
      element: <ReconnectRef title={trans('affiliate.reconnectRef')} />,
    },
    // {
    //   path: '/club',
    //   element: <Club title={'TKClub'} />,
    // },
    {
      path: '/streaming',
      element: <Streaming title={trans('general.title.sportTV')} />,
    },
    {
      path: '/movie',
      element: <Movie title={trans('general.title.movie')} />,
    },
    {
      path: '/porn',
      element: <Porn title={trans('general.title.porn')} />,
    },
    {
      path: '/fund',
      element: <Fund title={trans('general.title.fund')} />,
    },
    {
      path: '/deposit',
      element: <Deposit title={trans('general.title.deposit')} />,
    },
    {
      path: '/autoDeposit',
      element: <AutoDeposit title={trans('general.title.autoDeposit')} />,
    },
    {
      path: '/commRecord',
      element: <CommRecord title={trans('general.title.gameComm')} />,
    },
    {
      path: '/comm',
      element: <Comm title={trans('general.title.commSummary')} />,
    },
    {
      path: '/comm2wallet',
      element: <Comm2Wallet title={trans('transfer.WalTxt')} />,
    },
    {
      path: '/comm2bank',
      element: <Comm2Bank title={trans('transfer.BankTxt')} />,
    },
    {
      path: '/topupDetail',
      element: <TopupDetail title={trans('general.title.bankDetail')} />,
    },
    {
      path: '/topup',
      element: <Topup title={trans('general.title.topUp')} />,
    },
    {
      path: '/withdraw',
      element: <Withdraw title={trans('general.title.withdraw')} />,
    },
    {
      path: '/transaction',
      element: <Transaction title={trans('general.title.trxnList')} />,
    },
    {
      path: '/transactionDetail',
      element: <TransactionDetail title={trans('general.title.trxnDetail')} />,
    },
    {
      path: '/bankList',
      element: <BankList title={trans('general.title.bankList')} />,
    },
    {
      path: '/addBank',
      element: <AddBank title={trans('general.title.newBankAcc')} />,
    },
    {
      path: '/gameRecord',
      element: <GameRecord title={trans('general.title.gameRec')} />,
    },
    {
      path: '/notification',
      element: <Chat title={trans('general.title.chat')} />,
      state: {tabSelected: 'Info'},
    },

    // {
    //   path: '/mission',
    //   element: <Mission title={trans('general.title.mission')} />,
    // },

    {
      path: '/learnMore',
      element: <LearnMore title={trans('general.title.learnMore')} />,
    },
    {
      path: '/website',
      element: <Website title={trans('general.title.web')} />,
    },
    {
      path: '/premium',
      element: <Premium title={trans('general.title.premium')} />,
    },
    {
      path: '/profile',
      element: <Profile title={trans('general.title.profile')} />,
    },
    {
      path: '/avatar',
      element: <Avatar title={trans('general.title.avatar')} />,
    },
    {
      path: '/cs',
      element: <CustomerService title={trans('general.title.cs')} />,
    },
    {
      path: '/affiliate',
      element: <Affiliate title={trans('general.title.affiliate')} />,
    },
    // {
    //   path: '/changePin',
    //   element: <ChangePin title={trans('general.btn.changePass')} />,
    // },
    {
      path: '/downline',
      element: <Downline title={trans('general.title.downline')} />,
    },
    {
      path: '/downlineDetail',
      element: <DownlineDetail />,
    },
    {
      path: '/statement',
      element: <Statement />,
    },
    {
      path: '/gamesLog',
      element: <GamesLog />,
    },
    {
      path: '/changePassword',
      element: <ChangePassword />,
    },
    {
      path: '/loginProhibitionConfig',
      element: <LoginProhibitionConfig />,
    },
    {
      path: '/security',
      element: <SecurityMain />,
    },
    {
      path: '/setupGoogleAuth',
      element: <SetupGoogleAuth />,
    },
    {
      path: '/unbindGoogleAuth',
      element: <UnbindGoogleTwoFAAuth />,
    },
  ];

  return routes.map((route, index) => (
    <Route key={index} path={route.path} element={route.element} />
  ));
}
