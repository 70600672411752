import {useState, useEffect} from 'react';
import scssVariables from '../../resources/theme/Common.scss';

function useResponsive() {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const desktopWidth = scssVariables.SideMenuResponsiveWidth.replace(
        'px',
        '',
      );
      setIsDesktop(screenWidth > desktopWidth);
      setIsMobile(screenWidth <= desktopWidth);
    };

    // Initial call to set the initial state
    handleResize();

    // Event listener to update state on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {isDesktop, isMobile};
}

export default useResponsive;
