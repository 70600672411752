import React from 'react';
import './AmlPolicy.scss';
import BackNav from '../../common/components/navigationBar/BackNav';
import AmlPolicyContent from '../../resources/components/AmlPolicyContent';

const AmlPolicy = () => {
  return (
    <div className="amlPolicy-container container-padding">
      <div className="amlPolicy-style">
        <div className="amlPolicy-sticky-header">
          <BackNav />
        </div>
        <AmlPolicyContent />
      </div>
    </div>
  );
};

export default AmlPolicy;
