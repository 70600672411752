import React from 'react';
import {useNav} from '../../common/utils/helper';
import './FooterDescription.scss';

const FooterDescription = () => {
  return (
    <div className="footerDescription-txt">
      The content of fukuwin.com, including any certified aliases, is operated
      by DG Innovations B.V. in Curaçao under license 1668/JAZ of Curaçao
      eGaming (CEG), issued by the Governor of Curaçao to date October 1st, 1996
      under the ordinance of June 8th, 1993. The {<RgaBoldText />} contain
      important limitations and restrictions that are imposed for your
      protection and those of others. Before you decide to participate in any
      game, please make sure to carefully review the {<RgaBoldText />} and
      regularly check for changes. Either by accessing, registering, or using
      the gaming environment, you signify to acknowledge, agree and accept the{' '}
      {<RgaBoldText />}, and to be legally bound by it. The official version of
      the {<RgaBoldText />} is written in the English language, which supersedes
      all translations. Any modifications or updates will replace previous
      versions and become effective two weeks after being made available on the
      website. It is the user’s responsibility to regularly check for changes.
      By continuing to use the services after the updated terms become
      effective, the user is considered to have accepted the changes. Existing
      users may choose to discontinue using the products and services before the
      said update becomes effective.
    </div>
  );
};

const RgaBoldText = () => {
  const navigate = useNav();
  return (
    <span
      className="rgaBoldText-txt"
      onClick={() => {
        navigate('/rgaNGtc');
      }}>
      RGA and GT&C
    </span>
  );
};

export default FooterDescription;
