import React from 'react';
import './KycPolicy.scss';
import BackNav from '../../common/components/navigationBar/BackNav';
import KycPolicyContent from '../../resources/components/KycPolicyContent';

const KycPolicy = () => {
  return (
    <div className="kycPolicy-container container-padding">
      <div className="kycPolicy-style">
        <div className="kycPolicy-sticky-header">
          <BackNav />
        </div>
        <KycPolicyContent />
      </div>
    </div>
  );
};

export default KycPolicy;
