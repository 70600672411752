import React, {useEffect, useRef, useState} from 'react';
import './loginProhibitionConfig.scss';
import {useTranslation} from 'react-i18next';
import BackNav from '../../common/components/navigationBar/BackNav';
import BackButton from '../../common/components/navigationItem/BackButton';
import ProhibitionDropDown from '../../common/components/TextField/ProhibitionDropDown';
import ButtonComponent_v2 from '../../common/components/button/ButtonComponent_v2';
import FormComponent from '../../common/components/Form/FormComponent';
import api from '../../common/api';
import {
  MsgModalObject,
  useMsgModal,
} from '../../common/context/MsgModalContext';
import ErrMsgModal from '../../common/components/modal/ErrMsgModal';
import {useNav} from '../../common/utils/helper';
import {logOut} from '../../common/redux/userSlice';
import {useDispatch} from 'react-redux';

export default function LoginProhibitionConfig() {
  const navigate = useNav();
  const {pushMsgModal} = useMsgModal();
  const {t: trans} = useTranslation();
  const containerRef = useRef(null);
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const tfDurationName = 'duration';
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const prohibitionPeriod = [
    {
      months: 3,
    },
    {
      months: 6,
    },
    {
      months: 12,
    },
    {
      years: 3,
    },
    {
      years: 5,
    },
  ];

  const handleFieldChange = (e) => {
    const {name, value} = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleButtonClick = async () => {
    const isValid = await formRef.current.ValidateValue();

    if (isValid) {
      const duration = formData[tfDurationName];
      const currentDate = new Date();
      const banDate = new Date(currentDate);
      if (duration.years) {
        banDate.setFullYear(banDate.getFullYear() + duration.years);

        const totalMonths = duration.years * 12;
        duration.months = duration.months
          ? duration.months + totalMonths
          : totalMonths;
        delete duration.years;
      }
      if (duration.months) {
        banDate.setMonth(banDate.getMonth() + duration.months);
      }
      if (duration.weeks) {
        banDate.setDate(banDate.getDate() + duration.weeks * 7);
      }
      if (duration.days) {
        banDate.setDate(banDate.getDate() + duration.days);
      }
      SetBanDuration_API(duration, banDate);
    }
  };

  const SetBanDuration_API = (duration, banDate) => {
    api.SetBanDuration({
      duration: duration,
      success: () => {
        pushMsgModal(
          new MsgModalObject({
            content: (
              <ErrMsgModal
                title={'loginProhibition.successMsg'}
                content={trans('loginProhibition.successContent', {
                  banDate: banDate,
                })}
                onClick={() => {
                  dispatch(logOut());
                  navigate('/');
                }}
              />
            ),
          }),
        );
      },
    });
  };

  return (
    <div className="loginProhibitionConfig-container container-padding">
      <div className="loginProhibitionConfig-style">
        <div className="loginProhibitionConfig-sticky-header">
          <BackNav
            middleComponent={
              <div className="loginProhibitionConfig-title">
                {trans('loginProhibition.title')}
              </div>
            }
            rightComponent={
              <BackButton style={{visibility: 'hidden'}} />
            }></BackNav>
        </div>
        <div ref={containerRef} className="loginProhibitionConfig-content">
          <div className="loginProhibitionConfig-desc">
            {trans('loginProhibition.desc')}
          </div>
          <FormComponent
            ref={formRef}
            frameRef={containerRef}
            submitBtnRef={submitBtnRef}>
            <ProhibitionDropDown
              isFormChild={true}
              placeholder={trans('general.placeholder.pleaseSelect')}
              isRequired={true}
              itemList={prohibitionPeriod}
              name={tfDurationName}
              value={formData[tfDurationName]}
              onChange={handleFieldChange}
            />
          </FormComponent>
          <ButtonComponent_v2
            ref={submitBtnRef}
            text={trans('general.btn.submit')}
            btnClassName={'btnV2-container-row-center-p17-r10-mainshadow'}
            btnTextClassName={'btnV2-btn-txt-s20-w700-cMain'}
            onClick={() => {
              handleButtonClick();
            }}
          />
        </div>
      </div>
    </div>
  );
}
