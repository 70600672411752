import React, {useRef} from 'react';
import {GetHome} from '../../../resources/image/Image';
import {clientInfo} from '../../../resources';
import './NavBrandLogo.scss';
import {useNav} from '../../utils/helper';
import Dialog from '../root/dialog/Dialog';
import {useDispatch, useSelector} from 'react-redux';
import {setDummy} from '../../redux/optionSlice';

const NavBrandLogo = ({textClassName}) => {
  const dispatch = useDispatch();
  const {profile: user} = useSelector(({user}) => user);
  const HomeIcon = GetHome();
  const navigate = useNav();
  const gameUrl = useRef();

  return (
    <div
      className="nav-brand-container"
      onClick={() => {
        navigate('/');
        if (user.isTester.toLowerCase() === 'x') {
          function InputArea() {
            return (
              <textarea
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  padding: 20,
                  marginBottom: 20,
                }}
                ref={gameUrl}
                rows={1}
                placeholder="Typing ..."
              />
            );
          }
          Dialog.show({
            show: true,
            customize: InputArea(),
            cancelBtn: true,
            btnAct: () => {
              dispatch(setDummy(gameUrl.current.value));
            },
          });
          return;
        }
      }}>
      <img
        src={HomeIcon.smallLogo}
        className="nav-brand-icon"
        alt="nav-brand-icon"
      />
      {clientInfo.headerAppName ? (
        <div className={`${textClassName && textClassName} nav-brand-name`}>
          {clientInfo.headerAppName}
        </div>
      ) : null}
    </div>
  );
};

export default NavBrandLogo;
