import React from 'react';
import './KycPolicyContent.scss';
import PolicyTitle from '../../common/components/text/PolicyTitle';
import PolicySubSection from '../../common/components/text/PolicySubSection';

const KycPolicyContent = () => {
  return (
    <div className="kycPolicyContent-container">
      <PolicyTitle text={'Know Your Customer (KYC)'} />
      <div className="amlPolicy-content">
        <PolicySubSection
          title={`Introduction`}
          content={`By agreeing to the Terms you authorize us to undertake any verification checks we may require or that may be required by the third parties (including, regulatory bodies) to confirm your identity and contact details (the "Checks"). 

During this Checks we may restrict you from withdrawing funds from Your Account. 

If any information that you have provided is untrue, inaccurate, misleading, does not match your ID or otherwise incomplete we reserve the right to terminate your account immediately and/or prevent you from using the services, in addition to any other action that we may choose to take. 

If we are unable to confirm that you are at the Legal Age then we may suspend Your Account. If a fact that you were under the Legal age at the time you made any gambling or gaming transactions, then: 

Your Account will be closed; 
all transactions made during that time will become void, and all related funds deposited by you will be returned in amount; 
any winnings which you have accrued during that time will be forfeited from you and you will be required to return to us all funds that were withdrawn from Your Account. 

In the event of any change in your personal details You are required to inform us by contacting Support. 
Objective of the Policy
The Company shall request verification of the user of its Services at the following situations: 

1. Suspicion of fraud, money-laundering, criminal activity etc.; 
2. Doubts about the authenticity of the provided documents; 
3. Violation of conditions for using the services of the Company; 
4. Any other situation the Company shall deem appropriate. 

By means of that request the Company may demand a standard set of identification data and documents, as well as additional documents. 
The minimum set of identification data includes: 

- full name; 
- date of birth; 
- citizenship; 
- address of permanent residence; 
- an identification number; 

The required documents are: 

- actual passport of the client / ID card; 
- document confirming the address of the client's permanent residence at the given time (utility bill, statement of the status of the bank account, etc.) 

Additional documents are: 

- additional national identity card (driver's license, military ID, etc.); 
- photos of a user with an open passport against the background of his face (the document should be readable) 
- photo of the bank card on the front side (5 first and 4 last digits, owner's name and date of expiry shall be readable). 

Also the Company may ask the User to provide source of funds documentation which is important to make sure that user provides evidence that identifies the actual source(s) of funds that he uses to bet or play with. 

This information may be in the form of: 

- proof of earnings: Payslip/Director remuneration/Dividends/Pension; 
- a bank statement/savings account that clearly shows consistent incoming values from an identifiable source; 
- a Trust deed clearly showing a consistent entitlement to funds; 
- dated proof of an award/payment made to the user; 

The documents that the user provided shall be not approved because: 
- address/Name on documents don't match with account name/address; 
- illegible documents or copies; 
- document Not Acceptable (for example user sends a copy of a document that is not on the accepted documents for ID verification or a copy of mail envelope instead of an actual utility bill); 
- any other reasons employees or contractors of the Company shall deem appropriate. 
Upon the results of the analysis of the documents provided the Company shall make a solution according to the Terms Conditions, Privacy policy, License agreement of the Website and legislation requirements. `}
        />
        <PolicySubSection
          title={`Obligations`}
          content={`Due diligence (DD): Company's customers should be subject to a DD process and record keeping. 

Anonymous Accounts: Anonymous or nominal’ account records are not permitted. Any existing anonymous accounts or accounts believed to be nom de plume’ or that have inconsistent identification should be subject to appropriate due diligence to establish the identity and bona fides of the account holder at an early opportunity. In case it is impossible to determine identity of the account holder, the account shall be closed, winnings cancelled and deposits paid out to the the account from where they have been received or withhold in case return of the deposits turn out to be impossible or costly. 

Duplicate/Multiple Accounts: Many customers wish to operate parallel accounts in order to segregate their gambling spend. Notwithstanding this activity, Company shall identify and associate linked’ accounts that may belong or be under the control of the same person. Company shall be entitled to cancel additional accounts or accounts of the player in question, cancel winnings thus gained and return deposits to the owner of the account. 

Politically Exposed Persons (PEP). Company have to respond to any attempt to gamble by any person qualifying as Politically Exposed Person, i.e. any person holding significant public office (or who has held it at any time in the preceding year), having access to public funds or in a position of influence. PEPs include the readily identifiable family and associates of such persons. A risk based approach should be applied based on the value and scale of gambling and the location of such customer. The questionnaires shall include questions identifying PEPs. 

The Company reserves the exclusive right to unilaterally decline any clients' application and/or terminate further provision of services without any statements or explanations to the client in case of breach of KYC policy.`}
        />
      </div>
    </div>
  );
};

export default KycPolicyContent;
